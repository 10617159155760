import { combineReducers } from "redux";
import { handleAction, handleActions } from "redux-actions";
import { pick } from "lodash";

import * as decks from "../decks";
import * as shared from "../shared";

const initialStates = {
  byId: {},
  status: {
    loading: false,
  },
};

const picker = (suit) => ({
  ...pick(suit, ["id", "kind", "title", "type"]),
  cards: suit.cards.map((card) => card.id),
  deck: suit.deck.id,
});

const byId = handleAction(decks.actions.deck.get.success, (state, action) => (
  shared.reducers.collection({ state, action, key: "suits", picker: (suit) => picker(suit) })
), initialStates.byId);

const status = handleActions({
  [decks.actions.deck.get.request]: () => ({ loading: true }),
  [decks.actions.deck.get.success]: () => ({ loading: false }),
  [decks.actions.deck.get.error]: () => ({ loading: false }),
}, initialStates.status);

export const reducer = combineReducers({
  byId,
  status,
});
