import { Machine } from "xstate";

import { states } from "./states";

const stateMachines = {
  id: "game",
  ...states,
};

export const machine = Machine(stateMachines);
