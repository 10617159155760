import { call, put } from "redux-saga/effects";
import { api, actions } from ".";

export const serviceMessages = {
  * get() {
    try {
      const response = yield call(api.get);
      const payload = response ? response.data : {};

      yield put(actions.serviceMessages.get.success(payload));
    } catch (error) {
      yield put(actions.serviceMessages.get.error(error));
    }
  },
};
