import { uniqueId } from "lodash";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import * as actions from "./actions";

const initialState = {
  currentTimer: null,
};

const currentTimer = handleActions({
  [actions.command.start]: (_, action) => ({
    id: uniqueId(),
    beginTime: Date.now(),
    durationMs: action.payload.durationMs,
    pauseTime: null,
    pausedStateMs: 0,
  }),
  [actions.command.stop]: () => null,
  [actions.command.pause]: (state) => ({
    ...state,
    pauseTime: Date.now(),
  }),
  [actions.command.resume]: (state) => ({
    ...state,
    pauseTime: null,
    pausedStateMs: state.pausedStateMs + (Date.now() - state.pauseTime),
  }),
  [actions.command.refresh]: (state) => (state ? ({
    ...state,
    id: uniqueId(),
    beginTime: Date.now(),
  }) : null),
}, initialState.currentTimer);

export const reducer = combineReducers({
  currentTimer,
});
