import { backendService } from "../../services";
import { fromSnakeToCamel, fromCamelToSnake } from "../shared/utils";

export const get = async (payload) => {
  const { gameId } = payload;
  const url = `/games/${gameId}/relationships/notes`;

  const response = await backendService.get({ url });
  const { gameNotes } = fromSnakeToCamel(response.data);

  return {
    gameNotes,
  };
};

export const patch = async (payload) => {
  const { gameId, gameNotes } = payload;
  const url = `/games/${gameId}/relationships/notes`;

  const response = await backendService.patch({
    url,
    data: {
      game_notes: fromCamelToSnake(gameNotes),
    },
  });

  return { data: fromSnakeToCamel(response.data) };
};
