import { render } from "react-dom";
import React from "react";

import { Application } from "./Application";
import { configureStore, history } from "./store";

const container = document.getElementById("application");
const store = configureStore();

export { store };

render(<Application history={history} store={store} />, container);
