/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import ErrorBoundary, { setIgnoredActions } from "@topaasia/error-boundary";
import { Translation } from "react-i18next";
import { get, pick } from "lodash";
import { backendService } from "../../services";

const ignoreActions = [
  "LOCATION_CHANGE",
];

setIgnoredActions(ignoreActions);

const networkErrorFilter = (error) => {
  const { responseURL } = error.request;
  const responseCode = get(error, "response.data.code", null);

  if (responseURL.includes("/v1/authentication/login")) {
    // Do not log network errors caused by invalid credentials.
    return responseCode === "invalid_credentials";
  }

  return true;
};

const ApplicationErrorBoundary = ({ store, children }) => (
  <Translation ns="components">
    {(t) => (
      <ErrorBoundary
        title={t("error-boundary.title")}
        message={t("error-boundary.message")}
        project="frontend"
        backendService={backendService}
        axios={backendService.getDefaultInstance()}
        networkErrorFilter={networkErrorFilter}
        getApplicationState={() => {
          const state = store.getState();

          return {
            game: pick(state.game, "id", "state", "current", "status"),
            players: get(state, "players.collections.all", []).map((p) => pick(p, "id")),
            gamesetup: get(state, "gamesetup.gameSetup", null),
          }
        }}>
          { children }
        </ErrorBoundary>
    )}
  </Translation>
);

ApplicationErrorBoundary.propTypes = {
  store: PropTypes.any.isRequired,
  children: PropTypes.any,
};

export default ApplicationErrorBoundary;
