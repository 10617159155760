import Jsona from "jsona";

import { utils } from "../shared";
import { fromCamelToSnake, fromSnakeToCamel } from "../shared/utils";
import { backendService, organizationService } from "../../services";

export const create = async (payload) => {
  const type = "games";
  const url = `/${type}`;

  const {
    deckId,
    gameTypeId,
    perspectiveIds,
    subject,
    teamName,
    isTestGame,
    gameMode,
    crystallizationTemplateId = null,
  } = payload;
  const organizationId = organizationService.getCurrent();

  const response = await backendService.post({
    url,
    data: {
      deck_id: deckId,
      organization_id: organizationId,
      perspective_ids: perspectiveIds,
      subject,
      team_name: teamName,
      is_test_game: isTestGame,
      game_type_id: gameTypeId,
      game_mode: gameMode,
      crystallization_template_id: crystallizationTemplateId,
    },
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const get = async (id) => {
  const response = await backendService.get({ url: `/games/${id}` });

  return { data: fromSnakeToCamel(response.data) };
};

export const tick = async ({ gameId, interval }) => {
  await backendService.patch({
    url: `/games/${gameId}/tick_clock`,
    data: {
      interval: interval / 1000,
    },
  });
};

export const setGameCodeClosed = async ({ gameId, gameCodeClosed }) => {
  const response = await backendService.patch({
    url: `games/${gameId}/game-code-closed`,
    data: {
      game_code_closed: gameCodeClosed,
    },
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const setEnablePlayerGameNotes = async ({ gameId, enablePlayerGameNotes }) => {
  const response = await backendService.patch({
    url: `games/${gameId}/enable-player-game-notes`,
    data: fromCamelToSnake({
      enablePlayerGameNotes,
    }),
  });

  return { data: fromSnakeToCamel(response.data) };
};

export const actionItems = {
  create: async (payload) => {
    const url = "/conclusions/action-plans/action-items";

    const response = await backendService.post({
      url,
      data: {
        topic_id: payload.topicId,
        game_id: payload.gameId,
        name: payload.name || "",
        assignee: payload.assignee || "",
        due_at: payload.dueAt,
      },
    });

    return { data: fromSnakeToCamel(response.data) };
  },

  destroy: async (payload) => {
    const url = `/conclusions/action-plans/action-items/${payload.id}`;

    const response = await backendService.destroy({ url });

    return { data: response.data };
  },

  patch: async (payload) => {
    const url = `/conclusions/action-plans/action-items/${payload.id}`;
    const { name, assignee, dueAt } = payload;

    const response = await backendService.patch({
      url,
      data: { name, assignee, due_at: dueAt },
    });

    return { data: fromSnakeToCamel(response.data) };
  },
};

export const findings = {
  update: async (payload) => {
    const url = `/conclusions/findings`;
    const { gameId, topicId, report } = payload;

    const response = await backendService.post({
      url,
      data: {
        game_id: gameId,
        topic_id: topicId,
        report,
      },
    });

    return {
      ...response,
      data: fromSnakeToCamel(response.data),
    };
  },
};

export const feedback = {
  create: async (payload) => {
    const url = `/feedback`;

    const response = await backendService.post({
      url,
      data: {
        game_id: payload.gameId,
        score: payload.score || 0,
        max_score: payload.maxScore,
        feedback_text: payload.feedbackText,
      },
    });

    const data = response.data.data.attributes;

    return { data: utils.fromSnakeToCamel(data) };
  },
};

export const patch = async (payload) => {
  const type = "games";
  const url = `/games/${payload.id}`;
  const transformed = {
    payload: new Jsona().serialize({
      stuff: {
        type,
        ...payload,
      },
    }),
  };

  const response = await backendService.patch({ url, data: transformed.payload });

  return response;
};

export const topaasia = {
  patch: async (payload) => {
    const { game } = payload;
    const url = `/games/${game.id}/relationships/topaasia`;
    const transformed = {
      payload: { data: payload.topaasia },
    };

    await backendService.patch({ url, data: transformed.payload });
  },
};

export const preround = {
  nextRound: async (payload) => {
    const { game } = payload;
    const url = `/games/${game.id}/start_next_round`;
    const response = await backendService.patch({ url });

    return { data: fromSnakeToCamel(response.data) };
  },
};

export const waitplayers = {
  patch: async (payload) => {
    const game = payload;

    const url = `/games/${game.id}/start_game`;
    const response = await backendService.patch({ url });

    return { data: fromSnakeToCamel(response.data) };
  },
};

export const crystallization = {
  patch: async (payload) => {
    const { game } = payload;
    const url = `/games/${game.id}/end_game`;
    const data = {
      visibility: payload.visibility,
    };
    const response = await backendService.patch({ url, data });

    const transformed = {
      response: {
        ...response,
        data: fromSnakeToCamel(response.data),
      },
    };

    return transformed.response;
  },
};

export const playerParticipations = {
  list: async ({ gameId }) => {
    const url = `/games/${gameId}/relationships/player-participations`;

    const response = await backendService.get({ url });
    return { data: fromSnakeToCamel(response.data.list) };
  },
  get: async ({ gameId, playerParticipationId }) => {
    const url = `/games/${gameId}/relationships/player-participations/${playerParticipationId}`;
    const response = await backendService.get({ url });
    return { data: fromSnakeToCamel(response.data) };
  },
  post: async ({ gameId, topicId, duration }) => {
    const url = `/games/${gameId}/relationships/player-participations`;

    const response = await backendService.post({
      url,
      data: { topic_id: topicId, duration },
    });

    return { data: fromSnakeToCamel(response.data) };
  },
  patch: async ({ gameId, playerParticipationId, state }) => {
    const url = `/games/${gameId}/relationships/player-participations/${playerParticipationId}`;

    const response = await backendService.patch({
      url,
      data: { state },
    });

    return { data: fromSnakeToCamel(response.data) };
  },
};

export const playerIdeas = {
  destroy: async ({ gameId, playerIdeaId }) => {
    const url = `/games/${gameId}/relationships/player-ideas/${playerIdeaId}`;
    const response = await backendService.destroy({ url });
    return { data: fromSnakeToCamel(response.data) };
  },
};
