import { call, put, take, fork, cancel, cancelled } from "redux-saga/effects";

import { actions, api } from ".";

function delay(duration) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), duration);
  });
}

export function* get(gameId) {
  try {
    yield put(actions.get.request(gameId));
    const response = yield call(api.get, gameId);
    const payload = response ? response.data : {};

    yield put(actions.get.success(payload));
  } catch (error) {
    yield put(actions.get.error(error));
    throw error;
  }
}

export function* fetch(action) {
  const { gameId } = action.payload;
  yield call(get, gameId);
  yield put(actions.fetch.success());
}

export function* remove(action) {
  try {
    const { gameId, playerId } = action.payload;
    yield call(api.remove, { gameId, playerId });
    yield put(actions.remove.success({ playerId }));
  } catch (error) {
    yield put(actions.remove.error(error));
  }
}

export function* poll({ gameId, interval }) {
  try {
    while (true) {
      yield call(get, gameId);
      yield call(delay, interval);
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.refresh.cancelled());
    }
  }
}

export function* pollDriver() {
  while (true) {
    const action = yield take(actions.refresh.request);
    // starts the task in the background
    const { gameId, interval = 3000 } = action.payload;

    const pollTask = yield fork(poll, { gameId, interval });

    // wait for the user stop action
    yield take(actions.refresh.stop);

    // this will throw a SagaCancellationException into the forked bgSync task
    yield cancel(pollTask);
  }
}
