import { backendService, organizationService } from "../../../services";
import { utils } from "../../shared";

export const list = async ({
  organizationId = organizationService.getCurrent(),
  page = 1,
  pageSize = 25,
  onlyVisible = false,
}) => {
  const url = `/dashboard/games`;

  const response = await backendService.get({
    url,
    data: {
      organization_id: organizationId,
      page,
      page_size: pageSize,
      only_visible: onlyVisible,
    },
  });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const search = async ({ organizationId, searchQuery }) => {
  const url = `/dashboard/game-search/text`;

  const response = await backendService.get({
    url,
    data: {
      organization_id: organizationId,
      search: searchQuery,
    },
  });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const massFilterOptions = async ({
  organizationId,
  includeOptions = ["game_types", "decks", "perspectives"],
}) => {
  const url = `/dashboard/game-search/mass-filter/options`;

  const response = await backendService.get({
    url,
    data: {
      organization_id: organizationId || organizationService.getCurrent(),
      include_options: includeOptions,
    },
  });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const massFilter = async ({
  organizationId,
  filters,
  page = 0,
  pageSize = 50,
  resultMode = "games",
  gameScope = "only_public",
}) => {
  const url = `/dashboard/game-search/mass-filter`;

  const {
    subjectFilter,
    gameMasterFilter,
    teamFilter,
    dateFilter,
    gameSetupFilter,
    organizationFilter,
    gameLanguageFilter,
  } = filters;

  const response = await backendService.post({
    url,
    data: {
      organization_id: organizationId,
      organization_ids: organizationFilter,
      game_languages: gameLanguageFilter,
      subject_filter: subjectFilter,
      subject_filter_require_exact: false,
      game_master_filter: gameMasterFilter,
      team_filter: teamFilter.query,
      team_filter_require_exact: teamFilter.requireExact,
      date_from: dateFilter.from || undefined,
      date_to: dateFilter.to || undefined,
      deck_ids: gameSetupFilter.deckIds || undefined,
      perspective_ids: gameSetupFilter.perspectiveIds || undefined,
      game_type_ids: gameSetupFilter.gameTypeIds || undefined,
      result_mode: resultMode,
      page,
      page_size: pageSize,
      game_scope: gameScope,
    },
  });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const csvExport = async ({ organizationId, filters }) => {
  const url = `/dashboard/game-search/generate-csv`;

  const {
    subjectFilter,
    gameMasterFilter,
    teamFilter,
    dateFilter,
    gameSetupFilter,
    organizationFilter,
    gameLanguageFilter,
  } = filters;

  const response = await backendService.post({
    url,
    data: {
      organization_id: organizationId,
      organization_ids: organizationFilter,
      game_languages: gameLanguageFilter,
      subject_filter: subjectFilter,
      game_master_filter: gameMasterFilter,
      subject_filter_require_exact: false,
      team_filter: teamFilter.query,
      team_filter_require_exact: teamFilter.requireExact,
      date_from: dateFilter.from || undefined,
      date_to: dateFilter.to || undefined,
      deck_ids: gameSetupFilter.deckIds || undefined,
      perspective_ids: gameSetupFilter.perspectiveIds || undefined,
      game_type_ids: gameSetupFilter.gameTypeIds || undefined,
    },
  });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const listActionItems = async ({
  organizationId = organizationService.getCurrent(),
  isDone = null,
  page = 1,
  pageSize = 100,
}) => {
  const url = `/dashboard/action-items`;

  const response = await backendService.get({
    url,
    data: {
      organization_id: organizationId,
      page,
      page_size: pageSize,
      is_done: isDone,
    },
  });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const patchActionItems = async ({ actionItems }) => {
  const url = `/dashboard/action-items`;

  const response = await backendService.patch({
    url,
    data: {
      action_items: actionItems.map((actionItem) => ({
        id: actionItem.id,
        is_done: actionItem.isDone,
      })),
    },
  });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const get = async ({ gameId }) => {
  const url = `/dashboard/games/${gameId}`;
  const response = await backendService.get({ url });

  return { data: utils.fromSnakeToCamel(response.data.game) };
};

export const destroy = async ({ gameId }) => {
  const url = `/dashboard/games/${gameId}`;
  const response = await backendService.destroy({ url });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const patch = async ({ gameId, visibility }) => {
  const url = `/dashboard/games/${gameId}`;
  const response = await backendService.patch({ url, data: { visibility } });

  return { data: utils.fromSnakeToCamel(response.data.game) };
};

export const report = async ({ gameId, userId }) => {
  const url = `/dashboard/games/${gameId}/report`;
  const response = await backendService.post({ url, data: { user_id: userId } });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const summarize = async ({ id }) => {
  const url = `/dashboard/games/${id}/summarize`;
  const response = await backendService.post({ url });

  return { data: utils.fromSnakeToCamel(response.data) };
};
