/* eslint-disable max-len */
import { Redirect, Switch, useLocation } from "react-router-dom";
import React, { useEffect, Suspense } from "react";
import loadable from "@loadable/component";

import AuthenticatedRoute from "../components/authenticated-route/AuthenticatedRoute";

import * as game from "../modules/game";
import ScrollToTop from "../components/router/ScrollToTop";
import DelayedFallback from "./DelayedFallback";

const ConfirmOrganizationView = loadable(() => import("../views/dashboard/confirm-organization/ConfirmOrganizationView"));
const DashboardView = loadable(() => import("../views/dashboard/common/DashboardView"));
const DashboardHomeView = loadable(() => import("../views/dashboard/home/DashboardHomeView"));
const DashboardAcceptInviteView = loadable(() => import("../views/dashboard/email-verify-view/accept-invite/DashboardAcceptInviteView"));
const DashboardActionItemsView = loadable(() => import("../views/dashboard/reports/action_items/DashboardActionItemsView"));
const DashboardChangePlanView = loadable(() => import("../views/dashboard/payments/DashboardChangePlanView"));
const DashboardCheckoutResultView = loadable(() => import("../views/dashboard/payments/DashboardCheckoutResultView"));
const DashboardDeckBuilderView = loadable(() => import("../views/dashboard/organization/deck-builder/DashboardDeckBuilderView"));
const DashboardEditDeckView = loadable(() => import("../views/dashboard/organization/deck-builder/dashboard-edit-deck-view/DashboardEditDeckView"));
const DashboardDecksView = loadable(() => import("../views/dashboard/organization/DashboardDecksView"));
const DashboardEditGameTypeView = loadable(() => import("../views/dashboard/organization/game-type-builder/DashboardEditGameTypeView"));
const DashboardFilterGamesView = loadable(() => import("../views/dashboard/reports/filter/DashboardFilterGamesView"));
const DashboardGameAnalysisView = loadable(() => import("../views/dashboard/reports/analysis/DashboardGameAnalysisView"));
const DashboardPersistedAnalysesView = loadable(() => import("../views/dashboard/reports/persisted_analyses/DashboardPersistedAnalysesView"));
const DashboardChildOrganizationsView = loadable(() => import("../views/dashboard/organization/child-organizations/DashboardChildOrganizationsView"));
const DashboardGamesView = loadable(() => import("../views/dashboard/games/DashboardGamesView"));
const DashboardGameTypesView = loadable(() => import("../views/dashboard/organization/game-types/DashboardGameTypesView"));
const DashboardGameTypeBuilderView = loadable(() => import("../views/dashboard/organization/game-type-builder/DashboardGameTypeBuilderView"));
const DashboardCrystallizationBuilderView = loadable(() => import("../views/dashboard/organization/crystallization-builder/DashboardCrystallizationBuilderView"));
const EditCrystallizationTemplateView = loadable(() => import("../views/dashboard/organization/crystallization-builder/edit/EditCrystallizationTemplateView"));
const DashboardGameView = loadable(() => import("../views/dashboard/game/DashboardGameView"));
const DashboardManageView = loadable(() => import("../views/dashboard/manage/DashboardManageView"));
const DashboardOrganizationView = loadable(() => import("../views/dashboard/organization/DashboardOrganizationView"));
const DashboardProductMarketingView = loadable(() => import("../views/dashboard/products/DashboardProductMarketingView"));
const DashboardProductsView = loadable(() => import("../views/dashboard/organization/products/DashboardProductsView"));
const DashboardProfileView = loadable(() => import("../views/dashboard/profile/DashboardProfileView"));
const DashboardProfileOnboardingView = loadable(() => import("../views/dashboard/profile/onboarding/DashboardProfileOnboardingView"));
const DashboardReportsView = loadable(() => import("../views/dashboard/reports/DashboardReportsView"));
const DashboardResetPasswordView = loadable(() => import("../views/dashboard/reset-password/DashboardResetPasswordView"));
const DashboardUsersView = loadable(() => import("../views/dashboard/organization/users/DashboardUsersView"));
const DashboardOrganizationManagerView = loadable(() => import("../views/dashboard/organization/organization-manager/DashboardOrganizationManagerView"));
const DashboardVerifyEmailView = loadable(() => import("../views/dashboard/email-verify-view/verify-email/DashboardVerifyTrialEmailView"));
const GameView = loadable(() => import("../views/game/GameView"));
const CreateGameState = loadable(() => import("../views/game/states/setup/CreateGameState"));
const CrystallizationState = loadable(() => import("../views/game/states/crystallization/CrystallizationState"));
const PickTopaasiaState = loadable(() => import("../views/game/states/pick-topaasia/PickTopaasiaState"));
const RoundState = loadable(() => import("../views/game/states/round/RoundState"));
const WaitPlayersState = loadable(() => import("../views/game/states/waitplayers/WaitPlayersState"));
const CompleteState = loadable(() => import("../views/game/states/complete/CompleteState"));
const IntroView = loadable(() => import("../views/intro/IntroView"));
const LoginView = loadable(() => import("../views/login/LoginView"));
const PlayView = loadable(() => import("../views/play/PlayView"));
const TokenExchangeView = loadable(() => import("../views/play/TokenExchangeView"));
const TrialView = loadable(() => import("../views/trial/TrialView"));
const RedeemCodeView = loadable(() => import("../views/redeem-code/RedeemCodeView"));

const { uuid } = game.schemas.regularExpressions

export const ApplicationRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/intro")) {
      [LoginView, PlayView, ConfirmOrganizationView].forEach((c) => c.preload());
    }
    if (location.pathname.includes("/login")) {
      [
        PlayView,
        ConfirmOrganizationView,
        DashboardHomeView,
      ].forEach((c) => c.preload());
    }
    if (location.pathname.includes("/dashboard")) {
      [
        DashboardView,
        DashboardHomeView,
        DashboardGamesView,
        DashboardReportsView,
        DashboardOrganizationView,
        DashboardProfileView,
        GameView,
        CreateGameState,
      ].forEach((c) => c.preload());
    }
    if (location.pathname.includes("/game") && !location.pathname.includes("dashboard")) {
      [
        GameView,
        CreateGameState,
        CrystallizationState,
        PickTopaasiaState,
        RoundState,
        WaitPlayersState,
        CompleteState,
      ].forEach((c) => c.preload());
    }
    if (location.pathname.includes("/dashboard/deck-builder")) {
      [
        DashboardEditDeckView,
      ].forEach((c) => c.preload());
    }
  }, [location]);

  return (
    <Suspense fallback={<DelayedFallback />}>
      <ScrollToTop>
        <Switch>
          <AuthenticatedRoute path="/dashboard/main" component={DashboardHomeView} />
          <AuthenticatedRoute path="/dashboard/games" component={DashboardGamesView} />
          <AuthenticatedRoute path="/dashboard/reports/action-items" component={DashboardActionItemsView} />
          <AuthenticatedRoute path="/dashboard/reports/game-filter/:filterData?" component={DashboardFilterGamesView} />
          <AuthenticatedRoute path="/dashboard/reports/game-analysis/:filterData?" component={DashboardGameAnalysisView} />
          <AuthenticatedRoute path="/dashboard/reports/persisted-analyses" component={DashboardPersistedAnalysesView} />
          <AuthenticatedRoute path="/dashboard/reports" component={DashboardReportsView} />
          <AuthenticatedRoute path="/dashboard/users" component={DashboardUsersView} />
          <AuthenticatedRoute path="/dashboard/deck-builder/create" component={DashboardEditDeckView} />
          <AuthenticatedRoute path="/dashboard/deck-builder/edit/:deckId" component={DashboardEditDeckView} />
          <AuthenticatedRoute path="/dashboard/deck-builder" component={DashboardDeckBuilderView} />
          <AuthenticatedRoute path="/dashboard/crystallization-builder/edit/:crystallizationTemplateId" component={EditCrystallizationTemplateView} />
          <AuthenticatedRoute path="/dashboard/crystallization-builder/new" component={EditCrystallizationTemplateView} />
          <AuthenticatedRoute path="/dashboard/crystallization-builder" component={DashboardCrystallizationBuilderView} />
          <AuthenticatedRoute path="/dashboard/game-type-builder/edit/:gameTypeId" component={DashboardEditGameTypeView} />
          <AuthenticatedRoute path="/dashboard/game-type-builder/new" component={DashboardEditGameTypeView} />
          <AuthenticatedRoute path="/dashboard/game-type-builder" component={DashboardGameTypeBuilderView} />
          <AuthenticatedRoute path="/dashboard/organization/decks" component={DashboardDecksView} />
          <AuthenticatedRoute path="/dashboard/organization/game-types" component={DashboardGameTypesView} />
          <AuthenticatedRoute path="/dashboard/organization/organization-manager" component={DashboardOrganizationManagerView} />
          <AuthenticatedRoute path="/dashboard/organization" component={DashboardOrganizationView} />
          <AuthenticatedRoute path="/dashboard/products" component={DashboardProductsView} />
          <AuthenticatedRoute path="/dashboard/manage/change-plan/plan/:subscriptionPlanId" component={DashboardChangePlanView} />
          <AuthenticatedRoute path="/dashboard/manage/purchase/product/:purchasableProductId" component={DashboardProductMarketingView} />
          <AuthenticatedRoute path="/dashboard/manage" component={DashboardManageView} />
          <AuthenticatedRoute path="/dashboard/profile/onboarding" component={DashboardProfileOnboardingView} />
          <AuthenticatedRoute path="/dashboard/profile" component={DashboardProfileView} />
          <AuthenticatedRoute path="/dashboard/child-organizations" component={DashboardChildOrganizationsView} />
          <AuthenticatedRoute path={`/dashboard/game/:gameId(${uuid})?`} component={DashboardGameView} />
          <AuthenticatedRoute path="/dashboard/payments/checkout" component={DashboardCheckoutResultView} />
          <Redirect from="/dashboard" to="/dashboard/main" />

          <AuthenticatedRoute path={`/game/:gameId(${uuid})?`} component={GameView} />
          <AuthenticatedRoute path="/intro" open component={IntroView} />
          <AuthenticatedRoute path="/play" component={PlayView} />
          <AuthenticatedRoute path="/login" open component={LoginView} />
          <AuthenticatedRoute path="/pick-organization" component={ConfirmOrganizationView} />
          <AuthenticatedRoute path={`/token/:token`} open component={TokenExchangeView} />
          <AuthenticatedRoute path="/trial" open component={TrialView} />
          <AuthenticatedRoute path="/redeem-code" open component={RedeemCodeView} />
          <AuthenticatedRoute path={`/invite/:code`} open component={DashboardAcceptInviteView} />
          <AuthenticatedRoute path={`/verify-email/:code`} open component={DashboardVerifyEmailView} />
          <AuthenticatedRoute path={`/reset-password/:userData/:resetToken`} open component={DashboardResetPasswordView} />

          <Redirect from="*" to="/intro" />
        </Switch>
      </ScrollToTop>
    </Suspense>
  );
};
