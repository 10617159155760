import { manage as actions } from "../actions";

export const initialState = {
  collections: {
    currentSubscriptionProduct: null,
    subscriptionProduct: null,
    subscriptionProducts: [],
    purchasableProducts: [],
    purchasedProducts: [],
    organizationEffects: {
      own: [],
    },
    changePlanPreview: null,
    paymentMethods: null,
  },
  status: {
    currentSubscriptionProduct: { loading: false, loaded: false },
    subscriptionProducts: { loading: false, loaded: false, error: false },
    purchasableProducts: { loading: false, loaded: false, error: false },
    purchasedProducts: { loading: false, loaded: false },
    paymentMethods: { loading: false, loaded: false },
    cancelSubscription: { loading: false, error: false },
    patchingOrganization: { loading: false, error: false },
    consumingProduct: { loading: false, error: false },
  },
};

// NOTE: These reducers do not use "createActions" since these are combined with other dashboard
// reducers in ../reducers.js

export const collections = {
  [actions.subscriptionProducts.current.success]: (state, action) => ({
    ...state,
    currentSubscriptionProduct: {
      ...action.payload.subscriptionPlan,
      pricing: action.payload.pricing,
      isStripeBilled: !!action.payload.pricing,
      subscriptionSchedule: action.payload.hasScheduledChange ? {
        ...action.payload.subscriptionSchedule,
        upcomingProduct: {
          ...action.payload.subscriptionSchedule.upcomingProduct.subscriptionPlan,
          pricing: action.payload.subscriptionSchedule.upcomingProduct.pricing,
          isStripeBilled: true,
        },
      } : null,
      hasScheduledChange: action.payload.hasScheduledChange,
      fixedExpiryDate: action.payload.fixedExpiryDate,
      hasFixedExpiryDate: !!action.payload.fixedExpiryDate,
      isActive: action.payload.isActive,
    },
  }),
  [actions.subscriptionProducts.current.clear]: (state) => ({
    ...state,
    currentSubscriptionProduct: null,
  }),
  [actions.subscriptionProducts.list.success]: (state, action) => ({
    ...state,
    subscriptionProducts: (action.payload || []).map((product) => ({
      ...product.subscriptionPlan,
      pricingOptions: product.pricingOptions,
    })),
  }),
  [actions.subscriptionProducts.byId.success]: (state, action) => ({
    ...state,
    subscriptionProduct: {
      ...action.payload.subscriptionPlan,
      pricingOptions: action.payload.pricingOptions,
    },
  }),
  [actions.subscriptionProducts.byId.clear]: (state) => ({
    ...state,
    subscriptionProduct: null,
  }),
  [actions.subscription.update.preview.success]: (state, action) => ({
    ...state,
    changePlanPreview: {
      subscriptionPlan: action.payload.subscriptionPlan,
      proration: action.payload.prorationPreview,
      paymentMethod: action.payload.paymentMethod,
    },
  }),
  [actions.subscription.update.preview.clear]: (state) => ({
    ...state,
    changePlanPreview: null,
  }),
  [actions.paymentMethods.list.success]: (state, action) => ({
    ...state,
    paymentMethods: {
      list: action.payload,
      default: (action.payload || []).find((paymentMethod) => paymentMethod.isDefault),
    },
  }),
  [actions.purchasableProducts.list.success]: (state, action) => ({
    ...state,
    purchasableProducts: action.payload || [],
  }),
  [actions.purchasableProducts.purchased.success]: (state, action) => ({
    ...state,
    purchasedProducts: action.payload || [],
  }),
  [actions.organizationEffects.own.success]: (state, action) => ({
    ...state,
    organizationEffects: {
      ...state.organizationEffects,
      own: action.payload,
    },
  }),
};

const generateLoadingActions = (actionRoot, keyName) => ({
  [actionRoot.request]: (state) => ({
    ...state, [keyName]: { loading: true, loaded: false, error: false },
  }),
  [actionRoot.success]: (state) => ({
    ...state, [keyName]: { loading: false, loaded: true, error: false },
  }),
  [actionRoot.error]: (state) => ({
    ...state, [keyName]: { loading: false, loaded: false, error: true } }),
});

const generateProcessingActions = (actionRoot, keyName) => ({
  [actionRoot.request]: (state) => ({
    ...state,
    [keyName]: { loading: true, error: false },
  }),
  [actionRoot.success]: (state) => ({
    ...state,
    [keyName]: { loading: false, error: false },
  }),
  [actionRoot.error]: (state) => ({
    ...state,
    [keyName]: { loading: false, error: false },
  }),
});

export const status = {
  ...generateLoadingActions(actions.subscriptionProducts.current, "currentSubscriptionProduct"),
  ...generateLoadingActions(actions.subscriptionProducts.list, "subscriptionProducts"),
  ...generateLoadingActions(actions.purchasableProducts.list, "purchasableProducts"),
  ...generateLoadingActions(actions.purchasableProducts.purchased, "purchasedProducts"),
  ...generateLoadingActions(actions.paymentMethods.list, "paymentMethods"),
  ...generateLoadingActions(actions.organizationEffects.own, "ownOrganizationEffects"),

  ...generateProcessingActions(actions.subscription.cancel, "cancelSubscription"),
  ...generateProcessingActions(actions.organization.patch, "patchingOrganization"),
  ...generateProcessingActions(actions.purchasableProducts.consume, "consumingProduct"),
};
