import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { merge } from "lodash";

import { storeService } from "../../services";
import { actions } from ".";

const PERSIST_STATE_INSTRUCTIONS_READ_KEY = "persistStateInstructionsReadKey";

const storedInstructionsState = storeService.get(PERSIST_STATE_INSTRUCTIONS_READ_KEY) || {
  preround: false,
  round: false,
  pickTopaasia: false,
  crystallization: false,
};

const defaultState = {
  instructions: storedInstructionsState,
};

const instructions = handleActions({
  [actions.instructions.markAsRead]: (state, action) => {
    const newState = merge(state, action.payload);
    storeService.set(PERSIST_STATE_INSTRUCTIONS_READ_KEY, newState);
    return newState;
  },

  [actions.instructions.clear]: () => {
    storeService.remove(PERSIST_STATE_INSTRUCTIONS_READ_KEY);
    return null;
  },
}, defaultState.instructions);

export const reducer = combineReducers({
  instructions,
});
