import { call, put } from "redux-saga/effects";

import { actions, api } from ".";

export function* get(action) {
  try {
    yield put(actions.get.request(action.payload));

    const { organizationId } = action.payload;
    const response = yield call(api.get, organizationId);
    const payload = response ? response.data : {};

    yield put(actions.get.success(payload));
  } catch (error) {
    yield put(actions.get.error(error));
    throw error;
  }
}

export const deck = {
  * get(action) {
    try {
      yield put(actions.deck.get.request(action.payload));

      const { id } = action.payload;
      const response = yield call(api.deck.get, id);
      const payload = response ? response.data : {};

      yield put(actions.deck.get.success(payload));
    } catch (error) {
      yield put(actions.deck.get.error(error));
      throw error;
    }
  },
  * preview(action) {
    try {
      yield put(actions.deck.preview.request(action.payload));
      const { id } = action.payload;
      const response = yield call(api.deck.preview, id);
      const payload = response ? response.data : {};

      yield put(actions.deck.preview.success(payload));
    } catch (error) {
      yield put(actions.deck.preview.error(error));
    }
  },
};
