import { all, call, put, take, fork, cancel, cancelled, delay, retry, spawn } from "redux-saga/effects";
import i18next from "i18next";
import { get as safeGet } from "lodash";

import { actions, api } from ".";
import * as rounds from "../rounds";

export function* get(action) {
  try {
    yield put(actions.get.request(action.payload));

    const { id } = action.payload;
    const response = yield call(api.get, id);
    const payload = response ? response.data : {};

    if (payload.rounds) {
      yield all(
        payload.rounds.map((round) => call(rounds.sagas.round.get, rounds.actions.round.get.request({ id: round.id }))),
      );
    }

    i18next.changeLanguage(payload.deck.language);

    return yield put(actions.get.success(payload));
  } catch (error) {
    yield put(actions.get.error(error));
    throw error;
  }
}

export function* post(action) {
  try {
    yield put(actions.post.request(action.payload));

    const response = yield call(api.create, action.payload);
    const payload = response ? response.data : {};

    yield put(actions.post.success(payload));
  } catch (error) {
    yield put(actions.post.error(error));
    throw error;
  }
}

export function* setGameCodeClosed(action) {
  try {
    const response = yield call(api.setGameCodeClosed, action.payload);
    const payload = response ? response.data : {};
    yield put(actions.setGameCodeClosed.success(payload));
  } catch (error) {
    yield put(actions.setGameCodeClosed.error(error));
  }
}

export function* enablePlayerGameNotes(action) {
  try {
    const response = yield call(api.setEnablePlayerGameNotes, action.payload);
    const payload = response ? response.data : {};
    yield put(actions.enablePlayerGameNotes.success(payload));
  } catch (error) {
    yield put(actions.enablePlayerGameNotes.error(error));

    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
}

export const waitplayers = {
  *patch(action) {
    try {
      yield put(actions.waitplayers.patch.request(action.payload));

      const response = yield call(api.waitplayers.patch, action.payload);
      const payload = response ? response.data : {};

      yield put(actions.waitplayers.patch.success(payload));
    } catch (error) {
      yield put(actions.waitplayers.patch.error(error));
    }
  },
};

export const preround = {
  *nextRound(action) {
    yield put(actions.preround.nextRound.request(action.payload));

    const response = yield call(api.preround.nextRound, action.payload);
    const payload = response ? response.data : {};

    yield put(actions.preround.nextRound.success(payload));
  },
};

export const actionItems = {
  *post(action) {
    function* hideError({ delayMs }) {
      yield delay(delayMs);
      yield put(actions.actionItems.post.clearError());
    }

    try {
      const response = yield retry(2, 5000, api.actionItems.create, action.payload);
      const payload = response ? response.data : {};

      yield put(actions.actionItems.post.success(payload));
    } catch (error) {
      yield put(actions.actionItems.post.error(error));
      yield spawn(hideError, { delayMs: 5000 });
    }
  },

  *patch(action) {
    try {
      const response = yield call(api.actionItems.patch, action.payload);
      const payload = response ? response.data : {};

      yield put(actions.actionItems.patch.success(payload));
    } catch (error) {
      yield put(actions.actionItems.patch.error(error));
      yield delay(3000);
      yield put(actions.actionItems.patch.request(action.payload));
    }
  },

  *destroy(action) {
    function* hideError({ delayMs, id }) {
      yield delay(delayMs);
      yield put(actions.actionItems.destroy.clearError({ id }));
    }

    try {
      const response = yield retry(2, 3000, api.actionItems.destroy, action.payload);
      const payload = response ? response.data : {};

      yield put(actions.actionItems.destroy.success(payload));
    } catch (error) {
      yield put(actions.actionItems.destroy.error({ ...error, id: action.payload.id }));
      yield spawn(hideError, { delayMs: 5000, id: action.payload.id });
    }
  },
};

export const findings = {
  *post(action) {
    try {
      const response = yield call(api.findings.update, action.payload);
      const payload = response ? response.data : {};

      yield put(actions.findings.post.success(payload));
    } catch (error) {
      yield put(actions.findings.post.error(error));
      yield delay(3000);
      yield put(actions.findings.post.request(action.payload));
    }
  },
};

export const feedback = {
  *post(action) {
    try {
      const response = yield call(api.feedback.create, action.payload);
      const payload = response ? response.data : {};

      yield put(actions.feedback.post.success(payload));
    } catch (error) {
      yield put(actions.feedback.post.error(error));
      yield delay(3000);
      yield put(actions.feedback.post.request(action.payload));
    }
  },
};

export const crystallization = {
  *patch(action) {
    try {
      yield put(actions.crystallization.patch.request(action.payload));
      const response = yield call(api.crystallization.patch, action.payload);
      const payload = response ? response.data : {};
      yield put(actions.crystallization.patch.success(payload));
    } catch (error) {
      const isConflict = safeGet(error, "response.status") === 409;
      const isAlreadyCompleted = safeGet(error, "response.data.code") === "game_already_completed";
      if (isConflict && isAlreadyCompleted) {
        // Allow the UI to move on since the game is saved.
        // Note: this is a hacky solution to a very rare bug.
        const payload = safeGet(error, "response.data.game");
        yield put(actions.crystallization.patch.success(payload));
      } else {
        throw error;
      }
    }
  },
};

export const playerParticipations = {
  *list(action) {
    try {
      const { gameId } = action.payload;
      const response = yield call(api.playerParticipations.list, { gameId });
      const payload = response ? response.data : {};

      yield put(actions.playerParticipations.list.success(payload));
    } catch (error) {
      yield put(actions.playerParticipations.list.error(error));
      throw error;
    }
  },
  *get(action) {
    try {
      const { gameId, playerParticipationId } = action.payload;
      const response = yield call(api.playerParticipations.get, { gameId, playerParticipationId });
      const payload = response ? response.data : {};

      yield put(actions.playerParticipations.get.success(payload));
    } catch (error) {
      yield put(actions.playerParticipations.get.error(error));
      throw error;
    }
  },
  *post(action) {
    try {
      const { gameId, topicId, duration } = action.payload;
      const response = yield call(api.playerParticipations.post, { gameId, topicId, duration });
      const payload = response ? response.data : {};
      yield put(actions.playerParticipations.post.success(payload));
    } catch (error) {
      yield put(actions.playerParticipations.post.error(error));
      throw error;
    }
  },
  *patch(action) {
    try {
      const { gameId, playerParticipationId, state } = action.payload;
      const response = yield call(api.playerParticipations.patch, { gameId, playerParticipationId, state });
      const payload = response ? response.data : {};
      yield put(actions.playerParticipations.patch.success(payload));
    } catch (error) {
      yield put(actions.playerParticipations.patch.error(error));
      throw error;
    }
  },
  *refresh(gameId) {
    try {
      while (true) {
        yield call(this.list, { payload: { gameId } });
        yield delay(3000);
      }
    } finally {
      if (yield cancelled()) {
        yield put(actions.playerParticipations.refresh.canceled());
      }
    }
  },
  *pollDriver() {
    while (true) {
      const action = yield take(actions.playerParticipations.refresh.request);
      // starts the task in the background
      const { gameId, playerParticipationId } = action.payload;

      const pollTask = yield fork(this.refresh.bind(this), gameId, playerParticipationId);

      // wait for the user stop action
      yield take(actions.playerParticipations.refresh.stop);

      // this will throw a SagaCancellationException into the forked bgSync task
      yield cancel(pollTask);
    }
  },
};

export const playerIdeas = {
  *destroy(action) {
    try {
      const { gameId, playerIdeaId } = action.payload;
      const response = yield call(api.playerIdeas.destroy, { gameId, playerIdeaId });
      yield put(actions.playerIdeas.destroy.success(response.data));
    } catch (error) {
      yield put(actions.playerIdeas.destroy.error(error));
      throw error;
    }
  },
};

export const topaasia = {
  *patch(action) {
    try {
      yield put(actions.topaasia.patch.request(action.payload));
      yield call(api.topaasia.patch, action.payload);
      yield put(actions.topaasia.patch.success());
    } catch (error) {
      yield put(actions.topaasia.patch.error(error));
      throw error;
    }
  },
};

export function* postTick({ gameId, interval }) {
  try {
    yield put(actions.tick.request(gameId));
    yield call(api.tick, { gameId, interval });
    yield put(actions.tick.success(gameId));
  } catch (error) {
    yield put(actions.tick.error(error));
  }
}

export function* tick(gameId) {
  try {
    while (true) {
      const interval = 10000;
      yield call(postTick, { gameId, interval });
      yield delay(interval);
    }
  } catch (error) {
    yield delay(5000);
  } finally {
    if (yield cancelled()) {
      yield put(actions.tick.cancelled());
    }
  }
}

export function* tickDriver() {
  while (true) {
    const action = yield take(actions.tick.refresh);
    // starts the task in the background
    const { gameId } = action.payload;

    const pollTask = yield fork(tick, gameId);

    // wait for the user stop action
    yield take(actions.tick.stop);

    // this will throw a SagaCancellationException into the forked bgSync task
    yield cancel(pollTask);
  }
}
