import { createAction } from "redux-actions";

const namespaces = {
  dashboard: "DASHBOARD",
  game: "GAME",
  games: "GAMES",
  users: "USERS",
  organization: "ORGANIZATION",
  self: "USERS/SELF",
  invite: "INVITE",
  password: "PASSWORD",
  manage: "MANAGE",
};

export const games = {
  clear: createAction(`${namespaces.dashboard}/${namespaces.games}/CLEAR`),
  get: {
    request: createAction(`${namespaces.dashboard}/${namespaces.games}/GET/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.games}/GET/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.games}/GET/ERROR`),
  },
  search: {
    request: createAction(`${namespaces.dashboard}/${namespaces.games}/SEARCH/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.games}/SEARCH/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.games}/SEARCH/ERROR`),
    clear: createAction(`${namespaces.dashboard}/${namespaces.games}/SEARCH/CLEAR`),
  },
};

export const game = {
  get: {
    request: createAction(`${namespaces.dashboard}/${namespaces.game}/GET/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.game}/GET/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.game}/GET/ERROR`),
  },
  destroy: {
    request: createAction(`${namespaces.dashboard}/${namespaces.game}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.game}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.game}/DESTROY/ERROR`),

    requestWithRedirect: createAction(`${namespaces.dashboard}/${namespaces.game}/DESTROY/REQUEST-REDIRECT`),
  },
  report: {
    request: createAction(`${namespaces.dashboard}/${namespaces.game}/REPORT/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.game}/REPORT/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.game}/REPORT/ERROR`),
  },
  patch: {
    request: createAction(`${namespaces.dashboard}/${namespaces.game}/PATCH/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.game}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.game}/PATCH/ERROR`),
  },
  summarize: {
    request: createAction(`${namespaces.dashboard}/${namespaces.game}/SUMMARIZE/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.game}/SUMMARIZE/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.game}/SUMMARIZE/ERROR`),
  },
};

export const organization = {
  get: {
    request: createAction(`${namespaces.dashboard}/${namespaces.organization}/GET/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.organization}/GET/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.organization}/GET/ERROR`),
  },
  list: {
    request: createAction(`${namespaces.dashboard}/${namespaces.organization}/LIST/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.organization}/LIST/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.organization}/LIST/ERROR`),
  },
  change: {
    request: createAction(`${namespaces.dashboard}/${namespaces.organization}/CHANGE/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.organization}/CHANGE/SUCCESS`),
  },
  getOrganizationUmbrella: {
    request: createAction(`${namespaces.dashboard}/${namespaces.organization}/ORGANIZATION-UMBRELLA/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.organization}/ORGANIZATION-UMBRELLA/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.organization}/ORGANIZATION-UMBRELLA/ERROR`),
  },
  destroy: {
    prepare: {
      request: createAction(`${namespaces.dashboard}/${namespaces.organization}/DESTROY/PREPARE/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.organization}/DESTROY/PREPARE/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.organization}/DESTROY/PREPARE/ERROR`),
    },
    request: createAction(`${namespaces.dashboard}/${namespaces.organization}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.organization}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.organization}/DESTROY/ERROR`),
    cancel: createAction(`${namespaces.dashboard}/${namespaces.organization}/DESTROY/CANCEL`),
  },
};

export const users = {
  get: {
    request: createAction(`${namespaces.dashboard}/${namespaces.users}/GET/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.users}/GET/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.users}/GET/ERROR`),
  },
  self: {
    request: createAction(`${namespaces.dashboard}/${namespaces.self}/GET/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.self}/GET/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.self}/GET/ERROR`),
  },
  post: {
    request: createAction(`${namespaces.dashboard}/${namespaces.users}/POST/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.users}/POST/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.users}/POST/ERROR`),
  },
  patch: {
    request: createAction(`${namespaces.dashboard}/${namespaces.users}/PATCH/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.users}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.users}/PATCH/ERROR`),
  },
  moveUser: {
    request: createAction(`${namespaces.dashboard}/${namespaces.users}/MOVE-USER/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.users}/MOVE-USER/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.users}/MOVE-USER/ERROR`),
  },
  destroy: {
    request: createAction(`${namespaces.dashboard}/${namespaces.users}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.users}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.users}/DESTROY/ERROR`),
  },
  bulkDelete: {
    request: createAction(`${namespaces.dashboard}/${namespaces.users}/BULK-DELETE/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.users}/BULK-DELETE/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.users}/BULK-DELETE/ERROR`),
  },
  userAttributes: {
    patch: {
      request: createAction(`${namespaces.dashboard}/${namespaces.users}/USER-ATTRIBUTES/PATCH/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.users}/USER-ATTRIBUTES/PATCH/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.users}/USER-ATTRIBUTES/PATCH/ERROR`),
    },
  },
};

export const invite = {
  accept: {
    request: createAction(`${namespaces.dashboard}/${namespaces.invite}/ACCEPT/REQUEST`),
    success: createAction(`${namespaces.dashboard}/${namespaces.invite}/ACCEPT/SUCCESS`),
    error: createAction(`${namespaces.dashboard}/${namespaces.invite}/ACCEPT/ERROR`),
  },
};

export const password = {
  change: {
    init: {
      request: createAction(`${namespaces.dashboard}/${namespaces.password}/INIT/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.password}/INIT/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.password}/INIT/ERROR`),
    },
    post: {
      request: createAction(`${namespaces.dashboard}/${namespaces.password}/POST/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.password}/POST/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.password}/POST/ERROR`),
    },
  },
};

export const manage = {
  organization: {
    patch: {
      request: createAction(`${namespaces.dashboard}/${namespaces.manage}/ORGANIZATION/PATCH/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.manage}/ORGANIZATION/PATCH/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.manage}/ORGANIZATION/PATCH/ERROR`),
    },
  },
  subscriptionProducts: {
    current: {
      request: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/CURRENT/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/CURRENT/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/CURRENT/ERROR`),
      clear: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/CURRENT/CLEAR`),
    },
    list: {
      request: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/LIST/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/LIST/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/LIST/ERROR`),
    },
    byId: {
      request: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/PRODUCT/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/PRODUCT/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/PRODUCT/ERROR`),
      clear: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/PRODUCT/CLEAR`),
    },
  },
  purchasableProducts: {
    list: {
      request: createAction(`${namespaces.dashboard}/${namespaces.manage}/PURCHASABLE-PRODUCTS/LIST/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.manage}/PURCHASABLE-PRODUCTS/LIST/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.manage}/PURCHASABLE-PRODUCTS/LIST/ERROR`),
    },
    purchased: {
      request: createAction(`${namespaces.dashboard}/${namespaces.manage}/PURCHASABLE-PRODUCTS/PURCHASED/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.manage}/PURCHASABLE-PRODUCTS/PURCHASED/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.manage}/PURCHASABLE-PRODUCTS/PURCHASED/ERROR`),
    },
    consume: {
      request: createAction(`${namespaces.dashboard}/${namespaces.manage}/PURCHASABLE-PRODUCTS/CONSUME/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.manage}/PURCHASABLE-PRODUCTS/CONSUME/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.manage}/PURCHASABLE-PRODUCTS/CONSUME/ERROR`),
    },
  },
  organizationEffects: {
    own: {
      request: createAction(`${namespaces.dashboard}/${namespaces.manage}/ORGANIZATION-EFFECTS/OWN/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.manage}/ORGANIZATION-EFFECTS/OWN/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.manage}/ORGANIZATION-EFFECTS/OWN/ERROR`),
    },
  },
  subscription: {
    update: {
      preview: {
        request: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/UPDATE/PREVIEW/REQUEST`),
        success: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/UPDATE/PREVIEW/SUCCESS`),
        error: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/UPDATE/PREVIEW/ERROR`),
        clear: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/UPDATE/PREVIEW/CLEAR`),
      },
      request: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/UPDATE/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/UPDATE/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/UPDATE/ERROR`),
    },
    cancel: {
      request: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/CANCEL/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/CANCEL/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.manage}/SUBSCRIPTION/CANCEL/ERROR`),
    },
  },
  paymentMethods: {
    list: {
      request: createAction(`${namespaces.dashboard}/${namespaces.manage}/PAYMENTMETHODS/LIST/REQUEST`),
      success: createAction(`${namespaces.dashboard}/${namespaces.manage}/PAYMENTMETHODS/LIST/SUCCESS`),
      error: createAction(`${namespaces.dashboard}/${namespaces.manage}/PAYMENTMETHODS/LIST/ERROR`),
    },
  },
};
