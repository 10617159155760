import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import * as shared from "../shared";
import * as game from "../game";

const initialStates = {
  byId: {},
  status: {
    loading: false,
  },
};

const fields = ["id", "kind", "title", "hintText", "type", "index"];

const byId = handleActions({
  [game.actions.get.success]: (state, action) => (
    shared.reducers.collection({ state, action, fields, key: "topics" })
  ),

  [game.actions.post.success]: (state, action) => (
    shared.reducers.collection({ state, action, fields, key: "topics" })
  ),
}, initialStates.byId);

const status = handleActions({
  [game.actions.get.request]: () => ({ loading: true }),
  [game.actions.get.success]: () => ({ loading: false }),
  [game.actions.get.error]: () => ({ loading: false }),
}, initialStates.status);

export const reducer = combineReducers({
  byId,
  status,
});
