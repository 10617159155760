import axios from "axios";
import { get as safeGet } from "lodash";

import { logoutService, tokenService } from ".";
import { getCurrentLanguage } from "../modules/shared/language-utils";

const mediaType = "application/vnd.api+json";

const setAcceptLanguageHeader = (locale = "fi") => (
  { "Accept-Language": locale }
);

const setAuthorizationHeader = () => {
  const token = tokenService.get();

  return token
    ? { Authorization: `Bearer ${token}` }
    : {};
};

const setHeaders = ({ acceptLanguage = true, authorization = true } = {}) => {
  let headers = {};

  // Important side effect note:
  //
  // The HTTP header "Accept-Language" is currently being used to request
  // delivery of various emails matching the current locale of the user
  // interface.
  if (acceptLanguage) {
    headers = { ...headers, ...setAcceptLanguageHeader(getCurrentLanguage()) };
  }

  if (authorization) {
    headers = { ...headers, ...setAuthorizationHeader() };
  }

  return headers;
};

const resolveFallbackBaseUrl = () => {
  const { hostname, protocol, port } = window.location;
  const composedPort = port ? `:${port}` : "";
  const address = `${protocol}//api.${hostname}${composedPort}/v1`;

  return address;
};

const UNAUTHORIZED = 401;

axios.defaults.baseURL = process.env.API_BASE_URL || resolveFallbackBaseUrl();
axios.defaults.timeout = 10000; // 10 seconds
axios.defaults.headers.common.Accept = mediaType;
axios.defaults.headers.common["Content-Type"] = mediaType;

// Hard-catch 401 UNAUTHORIZED errors to easily handle them globally.
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = safeGet(error, "response", { status: 0 });

    if (status === UNAUTHORIZED) {
      logoutService.logout();
      window.location.replace("/");
    }

    return Promise.reject(error);
  },
);

// Intercept requests to renew the authorization header token when necessary
axios.interceptors.request.use(
  async (request) => {
    const token = tokenService.get();

    // Do not renew if there is no token (not logged in)
    if (!token) { return request; }
    const tokenLife = tokenService.tokenLifeSeconds(token);

    // Renew the token if there is less than an hour left
    if (tokenLife > 3600) { return request; }

    // Avoid multiple renews at the same time
    if (tokenService.getIsRenewingToken()) { return request; }

    // Renew token before doing the actual request
    try {
      tokenService.setIsRenewingToken(true);
      const data = {};
      const axiosClone = axios.create(); // Prevent interceptors from re-triggering
      const response = await axiosClone.post("/authentication/login/renew", data, { headers: setHeaders() });
      const newToken = response.data.token;
      tokenService.set(newToken);
    } finally {
      tokenService.setIsRenewingToken(false);
    }

    return request;
  },
  (error) => Promise.reject(error),
);

export const getDefaultInstance = () => axios;

export const get = async ({ url, data }) => axios.get(url, { headers: setHeaders(), params: data });
export const post = async ({ url, data }) => axios.post(url, data, { headers: setHeaders() });
export const patch = async ({ url, data }) => axios.patch(url, data, { headers: setHeaders() });
export const put = async ({ url, data }) => axios.put(url, data, { headers: setHeaders() });
export const destroy = async ({ url }) => axios.delete(url, { headers: setHeaders() });

export const serverTime = async () => {
  const url = "server/time";
  const clientTime = Date.now();
  const response = await axios.get(url, { headers: setHeaders(), params: { client_time: clientTime } });

  // Not super accurate (does not account for request time) but will serve its purpose
  return { serverTime: response.data.server_time, offset: response.data.time_offset };
};
