/* eslint-disable import/no-mutable-exports */
import ReactGA from "react-ga";
import { isFunction } from "lodash";

let GA = ReactGA;

if (process.env.ENABLE_GA !== "true") {
  GA = {};

  Object.keys(ReactGA).forEach((reactGAProp) => {
    if (isFunction(ReactGA[reactGAProp])) {
      GA[reactGAProp] = () => {};
    }
  });
}

const TopaasiaGA = {
  ...GA,
};

export default TopaasiaGA;
