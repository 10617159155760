import { combineReducers } from "redux";
import { handleAction, handleActions } from "redux-actions";
import { pick } from "lodash";

import * as shared from "../shared";
import { actions } from ".";

const initialStates = {
  byId: {},
  collections: {
    all: [],
    deckPreview: null,
  },
  status: {
    loading: false,
    loadingPreview: false,
  },
};

const picker = (deck) => ({
  ...pick(deck, [
    "id",
    "title",
    "type",
    "deckColor",
    "deckDescription",
    "deckCatalogId",
    "suits",
    "contentCoop",
    "privateDeck",
    "isPublished",
    "language",
  ]),
  suits: deck.suits,
});

const all = handleAction(actions.get.success, (state, action) => {
  const decks = action.payload || [];
  return decks.map((deck) => deck.id);
}, initialStates.collections.all);

const deckPreview = handleActions({
  [actions.deck.preview.success]: (state, action) => action.payload,
  [actions.deck.preview.close]: () => null,
}, initialStates.collections.deckPreview);

const byId = handleActions({
  [actions.get.success]: (state, action) => shared.reducers.collection({
    state, action, picker: (deck) => picker(deck),
  }),

  [actions.deck.get.success]: (state, action) => shared.reducers.singular({
    state, action, picker: (deck) => picker(deck),
  }),
}, initialStates.byId);

const status = handleActions(
  {
    [actions.get.request]: () => ({ loading: true }),
    [actions.get.success]: () => ({ loading: false }),
    [actions.get.error]: () => ({ loading: false }),
    [actions.deck.get.request]: () => ({ loading: true }),
    [actions.deck.get.success]: () => ({ loading: false }),
    [actions.deck.get.error]: () => ({ loading: false }),
    [actions.deck.preview.request]: () => ({ loadingPreview: true }),
    [actions.deck.preview.success]: () => ({ loadingPreview: false }),
    [actions.deck.preview.error]: () => ({ loadingPreview: false }),
  },
  initialStates.status,
);

const collections = combineReducers({
  all,
  deckPreview,
});

export const reducer = combineReducers({
  byId,
  collections,
  status,
});
