import { take, fork, cancel } from "redux-saga/effects";

export function generatePollDriver({ startAction, stopAction, pollSaga }) {
  return function* pollDriver() {
    while (true) {
      const action = yield take(startAction);
      // starts the task in the background
      const pollTask = yield fork(pollSaga, action);

      // wait for the user stop action
      yield take(stopAction);

      // this will throw a SagaCancellationException into the forked bgSync task
      yield cancel(pollTask);
    }
  };
}

export function generateDelay(duration) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), duration);
  });
}
