import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { configuration } from "../config";

const { languages } = configuration;

i18next
  .use(LanguageDetector)
  .init({
    debug: languages.ui.debug || false,
    detection: {
      caches: languages.ui.cache.sources,
      lookupCookie: languages.ui.cache.key,
      lookupLocalStorage: languages.ui.cache.key,
      order: ["cookie", "localStorage", "navigator", "htmlTag"],
    },
    fallbackLng: languages.ui.fallback,
    interpolation: { escapeValue: false },
    load: "languageOnly",
    lowerCaseLng: true,
    resources: languages.ui.resources,
  });

export { i18next as i18n };
