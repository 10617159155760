import { createAction } from "redux-actions";

const namespaces = {
  notes: "GAME-NOTES",
};

export const gameNoteActions = {
  ui: {
    openNotes: createAction(`${namespaces.notes}/UI/OPEN`),
    closeNotes: createAction(`${namespaces.notes}/UI/CLOSE`),
  },
  
  get: {
    request: createAction(`${namespaces.notes}/GET/REQUEST`),
    success: createAction(`${namespaces.notes}/GET/SUCCESS`),
    error: createAction(`${namespaces.notes}/GET/ERROR`),
  },
  
  patch: {
    request: createAction(`${namespaces.notes}/PATCH/REQUEST`),
    success: createAction(`${namespaces.notes}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.notes}/PATCH/ERROR`),
  },
  
  refresh: {
    request: createAction(`${namespaces.gameNotes}/REFRESH/REQUEST`),
    canceled: createAction(`${namespaces.gameNotes}/REFRESH/ERROR`),
    stop: createAction(`${namespaces.gameNotes}/REFRESH/STOP`),
  },
};
