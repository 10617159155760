import { transitions } from ".";

// Game (id: game)
export const states = {
  initial: "initial",
  states: {
    initial: {
      on: { [transitions.initial.load]: "loading" },
    },

    loading: {
      onEntry: [transitions.loading.request.toString()],
      on: {
        [transitions.loading.success]: "resolve",
        [transitions.loading.error]: "initial",
      },
    },

    resolve: {
      onEntry: [transitions.resolve.current.toString()],
      on: {
        [transitions.resolve.setup]: "#game.setup",
        [transitions.resolve.waitplayers]: "#game.waitplayers",
        [transitions.resolve.preround]: "#game.preround",
        [transitions.resolve.round]: "#game.round",
        [transitions.resolve.pickTopaasia]: "#game.pickTopaasia",
        [transitions.resolve.crystallization]: "#game.crystallization",
        [transitions.resolve.complete]: "#game.complete",
      },
    },

    setup: {
      initial: "game",
      states: {
        game: {
          onEntry: [
            transitions.setup.game.reset.toString(),
            transitions.setup.resolve.request(),
          ],
          on: {
            [transitions.setup.game.deck.change.request]: "game",
            [transitions.setup.game.team.change.request]: "game",
            [transitions.setup.game.submit]: "submitting",
          },
        },

        submitting: {
          onEntry: [transitions.setup.submitting.request.toString()],
          on: {
            [transitions.setup.submitting.success]: "#game.resolve",
            [transitions.setup.submitting.error]: "game",
          },
        },
      },
    },

    waitplayers: {
      initial: "initial",
      states: {
        initial: {
          onEntry: [transitions.waitplayers.resolve.request.toString()],
          on: {
            [transitions.waitplayers.submitting.request]: "submitting",
          },
        },
        submitting: {
          onEntry: [],
          on: {
            [transitions.waitplayers.submitting.success]: "#game.resolve",
          },
        },
      },
    },

    preround: {
      initial: "initial",
      states: {
        initial: {
          on: {
            [transitions.preround.submitting.success]: "#game.resolve",
            [transitions.preround.submitting.error]: "initial",
          },
        },
      },
    },

    round: {
      initial: "resolve",
      states: {
        resolve: {
          onEntry: [transitions.round.resolve.step.toString()],
          on: {
            [transitions.round.resolve.pickCandidates]: "pickCandidates",
            [transitions.round.resolve.pickChoice]: "pickChoice",
          },
        },

        pickCandidates: {
          initial: "resolve",
          states: {
            resolve: {
              onEntry: [transitions.round.pickCandidates.resolve.toString()],
              on: {
                [transitions.round.pickCandidates.picking.start]: "picking",
              },
            },
            picking: {
              on: {
                [transitions.round.pickCandidates.picking.pick]: "picking",
                [transitions.round.pickCandidates.picking.remove]: "picking",
                [transitions.round.pickCandidates.picking.submit]: "submitting",
                [transitions.round.pickCandidates.skipping.request]: "skipping",
              },
            },
            skipping: {
              on: {
                [transitions.round.pickCandidates.skipping.success]: "#game.round.pickChoice.prepareNextRound",
              },
            },
            submitting: {
              onEntry: [transitions.round.pickCandidates.submitting.request.toString()],
              on: {
                [transitions.round.pickCandidates.submitting.success]: "#game.resolve",
                [transitions.round.pickCandidates.submitting.error]: "picking",
              },
            },
          },
        },

        pickChoice: {
          initial: "resolve",
          states: {
            resolve: {
              onEntry: [transitions.round.pickChoice.resolve.toString()],
              on: {
                [transitions.round.pickChoice.picking.start]: "picking",
              },
            },
            picking: {
              on: {
                [transitions.round.pickChoice.picking.pick]: "picking",
                [transitions.round.pickChoice.picking.submit]: "submitting",
              },
            },

            submitting: {
              onEntry: [transitions.round.pickChoice.submitting.request.toString()],
              on: {
                [transitions.round.pickChoice.submitting.success]: "prepareNextRound",
                [transitions.round.pickChoice.submitting.error]: "picking",
              },
            },

            prepareNextRound: {
              onEntry: [transitions.round.pickChoice.prepareNextRound.entry.toString()],
              on: {
                [transitions.round.pickChoice.prepareNextRound.success]: "#game.resolve",
              },
            },
          },
        },
      },
    },

    pickTopaasia: {
      initial: "picking",
      states: {
        picking: {
          on: {
            [transitions.pickTopaasia.picking.pick]: "picking",
            [transitions.pickTopaasia.picking.submit]: "submitting",
          },
        },

        submitting: {
          onEntry: [transitions.pickTopaasia.submitting.request.toString()],
          on: {
            [transitions.pickTopaasia.submitting.success]: "#game.resolve",
            [transitions.pickTopaasia.submitting.error]: "picking",
          },
        },
      },
    },

    crystallization: {
      initial: "initial",
      states: {
        initial: {
          on: {
            [transitions.crystallization.initial.submit]: "submitting",
          },
        },

        submitting: {
          onEntry: [transitions.crystallization.submitting.request.toString()],
          on: {
            [transitions.crystallization.submitting.success]: "#game.complete",
            [transitions.crystallization.submitting.error]: "initial",
          },
        },
      },
    },

    complete: {
      type: "final",
      onEntry: [transitions.complete.resolve.toString()],
    },
  },
};
