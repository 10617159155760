import components from "./components.json";
import modules from "./modules.json";
import shared from "./shared.json";
import views from "./views.json";
import gameAnalysis from "./game-analysis.json";
import gameTrial from "./game-trial.json";
import homeView from "./home-view.json";
import onboarding from "./onboarding.json";

export const en = {
  components,
  modules,
  shared,
  views,
  gameAnalysis,
  gameTrial,
  homeView,
  onboarding,
};
