import { createAction } from "redux-actions";

const namespaces = {
  setup: "SETUP",
  waitplayers: "WAITPLAYERS",
  preround: "PREROUND",
  resolve: "RESOLVE",
  round: "ROUND",
  team: "TEAM",
  transitions: "TRANSITIONS",
};

const initial = {
  load: createAction(`${namespaces.transitions}/INITIAL/LOAD`),
};

const loading = {
  request: createAction(`${namespaces.transitions}/LOADING/REQUEST`),
  success: createAction(`${namespaces.transitions}/LOADING/SUCCESS`),
  error: createAction(`${namespaces.transitions}/LOADING/ERROR`),
};

const resolve = {
  complete: createAction(`${namespaces.transitions}/${namespaces.resolve}/COMPLETE`),
  current: createAction(`${namespaces.transitions}/${namespaces.resolve}/CURRENT`),
  error: createAction(`${namespaces.transitions}/${namespaces.resolve}/ERROR`),
  setup: createAction(`${namespaces.transitions}/${namespaces.resolve}/SETUP`),
  waitplayers: createAction(`${namespaces.transitions}/${namespaces.resolve}/WAITPLAYERS`),
  preround: createAction(`${namespaces.transitions}/${namespaces.resolve}/PREROUND`),
  round: createAction(`${namespaces.transitions}/${namespaces.resolve}/ROUND`),
  pickTopaasia: createAction(`${namespaces.transitions}/${namespaces.resolve}/PICK-TOPAASIA`),
  crystallization: createAction(`${namespaces.transitions}/${namespaces.resolve}/CRYSTALLIZATION`),
};

const setup = {
  resolve: {
    request: createAction(`${namespaces.transitions}/${namespaces.setup}/RESOLVE/REQUEST`),
  },
  game: {
    deck: {
      change: {
        request: createAction(`${namespaces.transitions}/SETUP/GAME/DECK/CHANGE/REQUEST`),
        success: createAction(`${namespaces.transitions}/SETUP/GAME/DECK/CHANGE/SUCCESS`),
        error: createAction(`${namespaces.transitions}/SETUP/GAME/DECK/CHANGE/ERROR`),
      },
      preview: {
        request: createAction(`${namespaces.transitions}/SETUP/GAME/DECK/PREVIEW/REQUEST`),
        success: createAction(`${namespaces.transitions}/SETUP/GAME/DECK/PREVIEW/SUCCESS`),
        error: createAction(`${namespaces.transitions}/SETUP/GAME/DECK/PREVIEW/ERROR`),
      },
    },

    team: {
      change: {
        request: createAction(`${namespaces.transitions}/SETUP/GAME/TEAM/CHANGE/REQUEST`),
        success: createAction(`${namespaces.transitions}/SETUP/GAME/TEAM/CHANGE/SUCCESS`),
        error: createAction(`${namespaces.transitions}/SETUP/GAME/TEAM/CHANGE/ERROR`),
      },
      new: createAction(`${namespaces.transitions}/SETUP/GAME/TEAM/NEW`),
      edit: createAction(`${namespaces.transitions}/SETUP/GAME/TEAM/EDIT`),
    },

    reset: createAction(`${namespaces.transitions}/SETUP/GAME/RESET`),
    submit: createAction(`${namespaces.transitions}/SETUP/GAME/SUBMIT`),
  },

  submitting: {
    request: createAction(`${namespaces.transitions}/SETUP/SUBMITTING/REQUEST`),
    success: createAction(`${namespaces.transitions}/SETUP/SUBMITTING/SUCCESS`),
    error: createAction(`${namespaces.transitions}/SETUP/SUBMITTING/ERROR`),
  },

  team: {
    new: {
      initial: {
        back: createAction(`${namespaces.transitions}/SETUP/TEAM/NEW/INITIAL/BACK`),
        submit: createAction(`${namespaces.transitions}/SETUP/TEAM/NEW/INITIAL/SUBMIT`),
      },

      submitting: {
        request: createAction(`${namespaces.transitions}/SETUP/TEAM/NEW/SUBMITTING/REQUEST`),
        success: createAction(`${namespaces.transitions}/SETUP/TEAM/NEW/SUBMITTING/SUCCESS`),
        error: createAction(`${namespaces.transitions}/SETUP/TEAM/NEW/SUBMITTING/ERROR`),
      },
    },

    edit: {
      initial: {
        back: createAction(`${namespaces.transitions}/SETUP/TEAM/EDIT/INITIAL/BACK`),
        submit: createAction(`${namespaces.transitions}/SETUP/TEAM/EDIT/INITIAL/SUBMIT`),
      },

      submitting: {
        request: createAction(`${namespaces.transitions}/SETUP/TEAM/EDIT/SUBMITTING/REQUEST`),
        success: createAction(`${namespaces.transitions}/SETUP/TEAM/EDIT/SUBMITTING/SUCCESS`),
        error: createAction(`${namespaces.transitions}/SETUP/TEAM/EDIT/SUBMITTING/ERROR`),
      },
    },
  },
};

const waitplayers = {
  resolve: {
    request: createAction(`${namespaces.transitions}/${namespaces.waitplayers}/RESOLVE/REQUEST`),
  },

  submitting: {
    request: createAction(`${namespaces.transitions}/${namespaces.waitplayers}/SUBMITTING/REQUEST`),
    success: createAction(`${namespaces.transitions}/${namespaces.waitplayers}/SUBMITTING/SUCCESS`),
    error: createAction(`${namespaces.transitions}/${namespaces.waitplayers}/SUBMITTING/ERROR`),
  },
};

const preround = {
  initial: {
    request: createAction(`${namespaces.transitions}/${namespaces.preround}/INITIAL/REQUEST`),
    submit: createAction(`${namespaces.transitions}/${namespaces.preround}/INITIAL/SUBMIT`),
  },

  submitting: {
    request: createAction(`${namespaces.transitions}/${namespaces.preround}/SUBMITTING/REQUEST`),
    success: createAction(`${namespaces.transitions}/${namespaces.preround}/SUBMITTING/SUCCESS`),
    error: createAction(`${namespaces.transitions}/${namespaces.preround}/SUBMITTING/ERROR`),
  },
};

const round = {
  resolve: {
    error: createAction(`${namespaces.transitions}/${namespaces.round}/RESOLVE/ERROR`),
    step: createAction(`${namespaces.transitions}/${namespaces.round}/RESOLVE/STEP`),

    pickCandidates: createAction(
      `${namespaces.transitions}/${namespaces.round}/RESOLVE/PICK-CANDIDATES`,
    ),

    pickChoice: createAction(
      `${namespaces.transitions}/${namespaces.round}/RESOLVE/PICK-CHOICE`,
    ),
  },

  pickCandidates: {
    resolve: createAction(`${namespaces.transitions}/${namespaces.round}/PICK-CANDIDATES/RESOLVE`),
    picking: {
      start: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CANDIDATES/PICKING/START`,
      ),

      pick: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CANDIDATES/PICKING/PICK`,
      ),

      remove: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CANDIDATES/PICKING/REMOVE`,
      ),

      submit: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CANDIDATES/PICKING/SUBMIT`,
      ),
    },

    submitting: {
      request: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CANDIDATES/SUBMITTING/REQUEST`,
      ),

      success: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CANDIDATES/SUBMITTING/SUCCESS`,
      ),

      error: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CANDIDATES/SUBMITTING/ERROR`,
      ),
    },

    skipping: {
      request: createAction(`${namespaces.transitions}/${namespaces.round}/PICK-CANDIDATES/SKIP/REQUEST`),
      success: createAction(`${namespaces.transitions}/${namespaces.round}/PICK-CANDIDATES/SKIP/SUCCESS`),
      error: createAction(`${namespaces.transitions}/${namespaces.round}/PICK-CANDIDATES/SKIP/ERROR`),
    },
  },

  pickChoice: {
    resolve: createAction(`${namespaces.transitions}/${namespaces.round}/PICK-CHOICE/RESOLVE`),

    picking: {
      start: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CHOICE/PICKING/START`,
      ),

      pick: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CHOICE/PICKING/PICK`,
      ),

      submit: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CHOICE/PICKING/SUBMIT`,
      ),
    },

    submitting: {
      request: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CHOICE/SUBMITTING/REQUEST`,
      ),

      success: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CHOICE/SUBMITTING/SUCCESS`,
      ),

      error: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CHOICE/SUBMITTING/ERROR`,
      ),
    },

    prepareNextRound: {
      entry: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CHOICE/PREPARE-NEXT/ENTRY`,
      ),
      success: createAction(
        `${namespaces.transitions}/${namespaces.round}/PICK-CHOICE/PREPARE-NEXT/SUCCESS`,
      ),
    },
  },
};

const pickTopaasia = {
  resolve: createAction(`${namespaces.transitions}/RESOLVE/PICK-TOPAASIA`),
  picking: {
    pick: createAction(`${namespaces.transitions}/PICK-TOPAASIA/PICKING/PICK`),
    submit: createAction(`${namespaces.transitions}/PICK-TOPAASIA/PICKING/SUBMIT`),
  },

  submitting: {
    request: createAction(`${namespaces.transitions}/PICK-TOPAASIA/SUBMITTING/REQUEST`),
    success: createAction(`${namespaces.transitions}/PICK-TOPAASIA/SUBMITTING/SUCCESS`),
    error: createAction(`${namespaces.transitions}/PICK-TOPAASIA/SUBMITTING/ERROR`),
  },
};

const crystallization = {
  initial: {
    submit: createAction(`${namespaces.transitions}/CRYSTALLIZATION/INITIAL/SUBMIT`),
  },

  submitting: {
    request: createAction(`${namespaces.transitions}/CRYSTALLIZATION/SUBMITTING/REQUEST`),
    success: createAction(`${namespaces.transitions}/CRYSTALLIZATION/SUBMITTING/SUCCESS`),
    error: createAction(`${namespaces.transitions}/CRYSTALLIZATION/SUBMITTING/ERROR`),
  },
};

const complete = {
  resolve: createAction(`${namespaces.transitions}/COMPLETE/RESOLVE`),

  // Actions to skip directly to game end
  goto: {
    request: createAction(`${namespaces.transitions}/COMPLETE/REQUEST`),
    success: createAction(`${namespaces.transitions}/COMPLETE/SUCCESS`),
    error: createAction(`${namespaces.transitions}/COMPLETE/ERROR`),
  },
};

export const transitions = {
  initial,
  loading,
  resolve,
  setup,
  waitplayers,
  preround,
  round,
  pickTopaasia,
  crystallization,
  complete,
};
