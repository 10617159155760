/**
 * Topaasia frontend configuration
 *
 * Important note:
 *
 * This configuration file is going to be publicly available,
 * thus never ever store any "secrets" here.
 */

import { en, fi, de, sv } from "./locales";

export const configuration = {
  languages: {
    decks: ["fi", "en", "de", "sv"],

    ui: {
      cache: {
        key: "language",
        sources: ["localStorage", "cookie"],
      },
      debug: false,
      fallback: "en",
      resources: {
        en,
        fi,
        de,
        sv,
      },
    },
  },

  links: {
    trial: {
      href: "https://play.topaasia.com/trial",
      text: "https://play.topaasia.com/trial",
      title: "",
    },

    topaasia: {
      title: "topaasia.com",
      url: "https://topaasia.com/",
    },

    privacyPolicy: {
      fi: "https://docs.google.com/document/d/1K-dFyjFB4CyZF5Q2krC2g1dG8MqQXXWb1cFe-v3R7Bo",
      en: "https://docs.google.com/document/d/1ItUkIFHEjhTHrxYTC1K3UtSOk16V50vTpf2i36LxfvA",
      de: "https://docs.google.com/document/d/1ItUkIFHEjhTHrxYTC1K3UtSOk16V50vTpf2i36LxfvA",
      sv: "https://docs.google.com/document/d/1ItUkIFHEjhTHrxYTC1K3UtSOk16V50vTpf2i36LxfvA",
    },

    termsOfService: {
      fi: "https://docs.google.com/document/d/1nn4jaTuSUjOjhAYvEUOfy6evR8takkeKqd8yXSqwDHk",
      en: "https://docs.google.com/document/d/17tZKULAGbzMRVq6K4vlv6Ir7s2GDlEeMLTnBxRGs9Og",
      de: "https://docs.google.com/document/d/17tZKULAGbzMRVq6K4vlv6Ir7s2GDlEeMLTnBxRGs9Og",
      sv: "https://docs.google.com/document/d/17tZKULAGbzMRVq6K4vlv6Ir7s2GDlEeMLTnBxRGs9Og",
    },
  },

  formats: {
    en: {
      date: "D MMMM YYYY",
      dateShorter: "M/D/YYYY",
      dateWithoutYear: "M/D",
      dateTime: "D MMMM YYYY [until] HH:mm",
      time: "HH:mm",
      duration: "H[h] mm[m]",
    },
    fi: {
      date: "D.M.YYYY",
      dateShorter: "D.M.YYYY",
      dateWithoutYear: "D.M.",
      dateTime: "D.M.YYYY [klo] HH.mm",
      time: "HH.mm",
      duration: "H[h] mm[m]",
    },
    de: {
      date: "D.M.YYYY",
      dateShorter: "D.M.YYYY",
      dateWithoutYear: "D.M.",
      dateTime: "D.M.YYYY, HH.mm",
      time: "HH.mm",
      duration: "H[h] mm[m]",
    },
    sv: {
      date: "D.M.YYYY",
      dateShorter: "D.M.YYYY",
      dateWithoutYear: "D.M.",
      dateTime: "D.M.YYYY, HH.mm",
      time: "HH.mm",
      duration: "H[h] mm[m]",
    },
  },

  timers: {
    // Values declared in seconds. Note: the first option is the default one.
    crystallization: [900, 600, 1200, 2400],
    pickChoice: [300, 180, 600, 900, 1200],
    pickTopaasia: [300, 180, 600, 900, 1200],
  },
};
