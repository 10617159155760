import React from "react";
import PropTypes from "prop-types";

function LoadingSpinner({ type = "dual-ring" }) {
  return (
    <div className="common-loading-spinner">
      <div className={`lds-${type}`}></div>
    </div>
  );
}

LoadingSpinner.propTypes = {
  type: PropTypes.oneOf(["dual-ring", "ring", "ellipsis"]),
};

export default LoadingSpinner;
