import { call, put, take, select } from "redux-saga/effects";

import * as api from "./api";
import { manage as actions } from "../actions";
import * as loginActions from "../../login/actions";

export const manage = {
  organization: {
    * patch(action) {
      try {
        const { organizationId, preferredLanguage } = action.payload;
        const response = yield call(api.manage.organization.patch, { organizationId, preferredLanguage });
        const payload = response ? response.data : {};
        yield put(loginActions.loginInfo.get.request());
        yield take(loginActions.loginInfo.get.success);
        yield put(actions.organization.patch.success(payload));
      } catch (error) {
        yield put(actions.organization.patch.error(error));
        throw error;
      }
    },
  },
  subscriptionProducts: {
    * current(action) {
      try {
        const { organizationId } = action.payload;
        const response = yield call(api.manage.subscriptionProducts.current, { organizationId });
        const payload = response ? response.data : {};
        yield put(actions.subscriptionProducts.current.success(payload));
      } catch (error) {
        yield put(actions.subscriptionProducts.current.error(error));
      }
    },
    * list(action) {
      try {
        const { organizationId } = action.payload;
        const response = yield call(api.manage.subscriptionProducts.list, { organizationId });
        const payload = response ? response.data : {};
        yield put(actions.subscriptionProducts.list.success(payload));
      } catch (error) {
        yield put(actions.subscriptionProducts.list.error(error));
      }
    },
    * byId(action) {
      try {
        const { subscriptionPlanId, organizationId } = action.payload;
        const response = yield call(api.manage.subscriptionProducts.byId, { subscriptionPlanId, organizationId });
        const payload = response ? response.data : {};
        yield put(actions.subscriptionProducts.byId.success(payload));
      } catch (error) {
        yield put(actions.subscriptionProducts.byId.error(error));
      }
    },
  },
  purchasableProducts: {
    * list(action) {
      try {
        const { organizationId } = action.payload;
        const response = yield call(api.manage.purchasableProducts.list, { organizationId });
        const payload = response ? response.data : {};
        yield put(actions.purchasableProducts.list.success(payload));
      } catch (error) {
        yield put(actions.purchasableProducts.list.error(error));
      }
    },
    * purchased(action) {
      try {
        const { organizationId } = action.payload;
        const response = yield call(api.manage.purchasableProducts.purchased, { organizationId });
        const payload = response ? response.data : {};
        yield put(actions.purchasableProducts.purchased.success(payload));
      } catch (error) {
        yield put(actions.purchasableProducts.purchased.error(error));
        throw error;
      }
    },
    * consume(action) {
      try {
        const { organizationProductPurchaseId, quantity } = action.payload;
        const response = yield call(api.manage.purchasableProducts.consume, {
          organizationProductPurchaseId,
          quantity,
        });
        const payload = response ? response.data : {};
        const organizationId = yield select((state) => state.login.collections.currentOrganization.id);
        yield put(loginActions.loginInfo.get.request());
        yield take(loginActions.loginInfo.get.success);
        yield put(actions.purchasableProducts.purchased.request({ organizationId }));
        yield take(actions.purchasableProducts.purchased.success);
        yield put(actions.organizationEffects.own.request({ organizationId }));
        yield take(actions.organizationEffects.own.success);
        yield put(actions.purchasableProducts.consume.success(payload));
      } catch (error) {
        yield put(actions.purchasableProducts.consume.error(error));
        throw error;
      }
    },
  },
  organizationEffects: {
    * own(action) {
      try {
        const { organizationId } = action.payload;
        const response = yield call(api.manage.organizationEffects.own, { organizationId });
        const payload = response ? response.data : {};
        yield put(actions.organizationEffects.own.success(payload));
      } catch (error) {
        yield put(actions.organizationEffects.own.error(error));
        throw error;
      }
    },
  },
  subscription: {
    update: {
      * preview(action) {
        try {
          const { organizationId, priceId } = action.payload;
          const response = yield call(api.manage.subscription.update.preview, { organizationId, priceId });
          const payload = response ? response.data : {};
          yield put(actions.subscription.update.preview.success(payload));
        } catch (error) {
          yield put(actions.subscription.update.preview.error(error));
          throw error;
        }
      },
    },
    * cancel(action) {
      try {
        const { organizationId, cancel } = action.payload;
        const response = yield call(api.manage.subscription.cancel, { organizationId, cancel });
        const payload = response ? response.data : {};

        yield put(actions.subscriptionProducts.current.request({ organizationId }));
        yield put(actions.subscription.cancel.success(payload));
      } catch (error) {
        yield put(actions.subscription.cancel.error(error));
        throw error;
      }
    },
  },
  paymentMethods: {
    * list(action) {
      try {
        const { organizationId } = action.payload;
        const response = yield call(api.manage.paymentMethods.list, { organizationId });
        const payload = response ? response.data : {};
        yield put(actions.paymentMethods.list.success(payload));
      } catch (error) {
        yield put(actions.paymentMethods.list.error(error));
      }
    },
  },
};
