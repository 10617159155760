import { backendService } from "../../../services";
import { utils } from "../../shared";

export const manage = {
  organization: {
    patch: async ({ organizationId, preferredLanguage }) => {
      const url = `dashboard/organization/${organizationId}`;
      const response = await backendService.patch({
        url,
        data: {
          preferred_language: preferredLanguage,
        },
      });

      return { data: utils.fromSnakeToCamel(response.data) };
    },
  },
  subscriptionProducts: {
    current: async ({ organizationId }) => {
      const url = `dashboard/subscription-products/current-product?organization_id=${organizationId}`;
      const response = await backendService.get({ url });

      return { data: utils.fromSnakeToCamel(response.data) };
    },
    list: async ({ organizationId }) => {
      const url = `dashboard/subscription-products/list-products?organization_id=${organizationId}`;
      const response = await backendService.get({ url });

      return { data: utils.fromSnakeToCamel(response.data.list) };
    },
    byId: async ({ organizationId, subscriptionPlanId }) => {
      const url = `dashboard/subscription-products/${subscriptionPlanId}?organization_id=${organizationId}`;
      const response = await backendService.get({ url });

      return { data: utils.fromSnakeToCamel(response.data) };
    },
  },
  purchasableProducts: {
    list: async ({ organizationId }) => {
      const url = `dashboard/purchasable-products/list-products?organization_id=${organizationId}`;
      const response = await backendService.get({ url });

      return { data: utils.fromSnakeToCamel(response.data.list) };
    },
    get: async ({ organizationId, purchasableProductId }) => {
      const url = `dashboard/purchasable-products/${purchasableProductId}?organization_id=${organizationId}`;
      const response = await backendService.get({ url });

      return { data: utils.fromSnakeToCamel(response.data.purchasable_product) };
    },
    purchased: async ({ organizationId }) => {
      const url = `dashboard/purchasable-products/list-purchased?organization_id=${organizationId}`;
      const response = await backendService.get({ url });

      return { data: utils.fromSnakeToCamel(response.data.list) };
    },
    consume: async ({ organizationProductPurchaseId, quantity }) => {
      const url = "/dashboard/purchasable-products/consume-product";
      const response = await backendService.patch({
        url,
        data: {
          organization_product_purchase_id: organizationProductPurchaseId,
          quantity,
        },
      });

      return { data: utils.fromSnakeToCamel(response.data) };
    },
  },
  organizationEffects: {
    own: async ({ organizationId }) => {
      const url = `/dashboard/organization-effects/list-own-effects?organization_id=${organizationId}`;
      const response = await backendService.get({ url });

      return { data: utils.fromSnakeToCamel(response.data.list) };
    },
  },
  subscription: {
    update: {
      preview: async ({ organizationId, priceId }) => {
        const url = `stripe/stripe-subscriptions/preview-subscription-proration`;

        const response = await backendService.get({
          url,
          data: {
            organization_id: organizationId,
            price_id: priceId,
          },
        });

        return { data: utils.fromSnakeToCamel(response.data) };
      },
    },
    cancel: async ({ organizationId, cancel }) => {
      const url = `/stripe/stripe-subscriptions/cancel-subscription`;

      const response = await backendService.post({
        url,
        data: {
          organization_id: organizationId,
          cancel,
        },
      });

      return { data: utils.fromSnakeToCamel(response.data) };
    },
  },
  paymentMethods: {
    list: async ({ organizationId }) => {
      const url = `stripe/stripe-subscriptions/payment-methods`;

      const response = await backendService.get({
        url,
        data: { organization_id: organizationId },
      });

      return { data: utils.fromSnakeToCamel(response.data.list) };
    },
  },
};
