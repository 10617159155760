import { addDays, addHours, addMonths, addWeeks, startOfToday } from "date-fns";
import i18next from "i18next";

const t = i18next.getFixedT(null, "modules");

export const dueAtToOptions = ({ from = startOfToday() }) => {

  const noon = addHours(from, 12);

  const dayFromNow = addDays(noon, 1).toISOString();
  const weekFromNow = addWeeks(noon, 1).toISOString();
  const monthFromNow = addMonths(noon, 1).toISOString();

  // A hacky way to convert ISO string to YYYY-MM-DD.
  const regex = /(\d{4})-(\d{2})-(\d{2}).*/;
  const format = "$1-$2-$3";

  const options = [
    { label: t("game.transformers.due-at-to-options.within-a-day"), value: dayFromNow.replace(regex, format) },
    { label: t("game.transformers.due-at-to-options.within-a-week"), value: weekFromNow.replace(regex, format) },
    { label: t("game.transformers.due-at-to-options.within-a-month"), value: monthFromNow.replace(regex, format) },
  ];

  return options;
};

export const defaultDueAtValue = (from = startOfToday()) => dueAtToOptions({ from })[0].value;
