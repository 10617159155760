import { createAction } from "redux-actions";

const namespaces = {
  players: "PLAYERS",
};

// Continuous update
export const refresh = {
  request: createAction(`${namespaces.players}/REFRESH/REQUEST`),
  stop: createAction(`${namespaces.players}/REFRESH/STOP`),
  cancelled: createAction(`${namespaces.players}/REFRESH/CANCELLED`),
};

// Single fetch
export const fetch = {
  request: createAction(`${namespaces.players}/FETCH/REQUEST`),
  success: createAction(`${namespaces.players}/FETCH/SUCCESS`),
};

export const get = {
  request: createAction(`${namespaces.players}/GET/REQUEST`),
  success: createAction(`${namespaces.players}/GET/SUCCESS`),
  error: createAction(`${namespaces.players}/GET/ERROR`),
};

export const remove = {
  request: createAction(`${namespaces.players}/REMOVE/REQUEST`),
  success: createAction(`${namespaces.players}/REMOVE/SUCCESS`),
  error: createAction(`${namespaces.players}/REMOVE/ERROR`),
};
