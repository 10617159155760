import { isArray, isEmpty, pick } from "lodash";

export const singular = ({ state, action, fields = [], picker }) => {
  const resource = action.payload;
  const nextState = { ...state };

  if (resource && resource.id) {
    if (picker && typeof picker === "function") {
      nextState[resource.id] = picker(resource);
    } else {
      nextState[resource.id] = {
        ...(!isEmpty(fields)
          ? pick(resource, fields)
          : resource
        ),
      };
    }
  }

  return nextState;
};

export const collection = ({ state, action, fields = [], key, picker }) => {
  let nextState = { ...state };

  const resources = key
    ? action.payload[key]
    : action.payload;

  if (isArray(resources) && !isEmpty(resources)) {
    resources.forEach((resource) => {
      nextState = {
        ...singular({
          state: nextState,
          action: { payload: resource },
          fields,
          picker,
        }),
      };
    });
  }

  return nextState;
};
