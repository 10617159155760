import { select, call, put, take, fork, cancel, cancelled } from "redux-saga/effects";
import { actions, api } from ".";
import * as players from "../players";

function delay(duration) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), duration);
  });
}

export function* get({ kind = "topaasia" }) {
  try {
    const state = yield select();
    const gameId = state.game.id;
    let roundId = null;

    if (kind === "round") {
      roundId = state.game.current.round.id;
    }

    yield put(actions.get.request());
    const response = yield call(api.get, { kind, gameId, roundId });
    const payload = response ? response.data : {};

    yield put(actions.get.success(payload));

    yield call(players.sagas.get, gameId);
  } catch (error) {
    yield put(actions.get.error(error));
    throw error;
  }
}

export function* create() {
  try {
    const state = yield select();
    const gameId = state.game.id;
    let roundId = null;
    let kind = "topaasia";

    if (state.game.state === "rounds") {
      roundId = state.game.current.round.id;
      kind = "round";
    }

    yield put(actions.create.request());

    const response = yield call(api.create, { gameId, kind, roundId });

    yield put(actions.create.success(response.data));
    yield get({ kind });
  } catch (error) {
    yield put(actions.create.error(error));
    throw error;
  }
}

export function* terminate(action) {
  try {
    const { gameId, pollId, kind } = action.payload;
    yield put(actions.terminate.request(gameId));
    const response = yield call(api.end, { gameId, pollId });
    yield put(actions.terminate.success(response.data));
    yield get({ kind });
  } catch (error) {
    yield put(actions.terminate.error(error));
    throw error;
  }
}

export function* poll({ kind }) {
  try {
    while (true) {
      yield call(get, { kind });
      yield call(delay, 3000);
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.refresh.cancelled());
    }
  }
}

export function* pollDriver() {
  while (true) {
    const action = yield take(actions.refresh.request);
    // starts the task in the background
    const { kind } = action.payload;

    const pollTask = yield fork(poll, { kind });

    // wait for the user stop action
    yield take(actions.refresh.stop);

    // this will throw a SagaCancellationException into the forked bgSync task
    yield cancel(pollTask);
  }
}
