import Jsona from "jsona";

import { backendService, organizationService } from "../../services";

export const get = async (organizationId) => {
  const include = "suits";
  const response = await backendService.get({ url: `/organizations/${organizationId}/decks?include=${include}` });
  const transformed = {
    response: {
      ...response,
      data: new Jsona().deserialize(response.data),
    },
  };

  return transformed.response;
};

export const deck = {
  get: async (id) => {
    const include = "perspectives,suits.cards";
    const organizationId = organizationService.getCurrent();
    const response = await backendService.get({
      url: `/decks/${id}?include=${include}`,
      data: { organization_id: organizationId },
    });
    const transformed = {
      response: {
        ...response,
        data: new Jsona().deserialize(response.data),
      },
    };

    return transformed.response;
  },
  preview: async (id) => {
    const include = "suits.cards";
    const organizationId = organizationService.getCurrent();
    const response = await backendService.get({
      url: `/decks/${id}?include=${include}`,
      data: { organization_id: organizationId },
    });
    const transformed = {
      response: {
        ...response,
        data: new Jsona().deserialize(response.data),
      },
    };

    return transformed.response;
  },
};
