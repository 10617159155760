import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { actions } from ".";

const initialState = {
  collections: {
    all: null,
  },
  status: {
    loadTime: null,
    loading: false,
  },
};

const collections = handleActions({
  [actions.get.success]: (_, action) => (
    { all: action.payload }
  ),
  [actions.remove.success]: (state, action) => {
    const { playerId } = action.payload;

    return {
      all: state.all.filter((p) => p.id !== playerId),
    };
  },
}, initialState.collections);

const status = handleActions({
  [actions.get.request]: () => ({ loading: true }),
  [actions.get.success]: () => ({ loading: false }),
  [actions.get.error]: () => ({ loading: false }),
}, initialState.status);

export const reducer = combineReducers({
  collections,
  status,
});
