import { applyMiddleware, compose, createStore } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { actionMonitorMiddleware } from "@topaasia/error-boundary";

import { configureReducers } from "./reducers";
import { sagas } from "./sagas";

import * as shared from "./modules/shared";
import * as states from "./modules/states";

const statechartsMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  const statechart = shared.selectors.getByPath({ path: "state", state });
  const nextMachine = states.machine.transition(statechart, action);
  const result = next(action);

  if (nextMachine && action.type !== states.actions.update.toString()) {
    if (nextMachine.history !== undefined) {
      store.dispatch(states.actions.update(nextMachine));
    }
  }

  nextMachine.actions.forEach((stateAction) => {
    if (stateAction.exec) {
      stateAction.exec();
    } else {
      store.dispatch({
        type: stateAction.type,
        payload: action.payload,
      });
    }
  });

  return result;
};

export const history = createBrowserHistory();

export function configureStore() {
  const initialState = {};
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    routerMiddleware(history),
    actionMonitorMiddleware,
    sagaMiddleware,
    statechartsMiddleware,
  ];

  const composeEnhancers = (
    typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) || compose;

  const enhancers = composeEnhancers(applyMiddleware(...middlewares));
  const reducers = configureReducers(history);
  const store = createStore(reducers, initialState, enhancers);

  sagaMiddleware.run(sagas);

  return store;
}
