import { actionMonitorMiddleware, getActionHistory, setIgnoredActions } from "./actionMonitorService";
import ErrorBoundary from "./ErrorBoundary";

export {
  actionMonitorMiddleware,
  getActionHistory,
  setIgnoredActions,
};

export default ErrorBoundary;
