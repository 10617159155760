import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// Ongoing process to remove circular dependencies and move away from
// index.js based exports.
import { reducer as dashboardReducer } from "./modules/dashboard";
import { reducer as gameTimerReducer } from "./modules/game-timer/reducers";

import * as decks from "./modules/decks";
import * as game from "./modules/game";
import * as intro from "./modules/intro";
import * as login from "./modules/login";
import * as notes from "./modules/notes";
import * as persist from "./modules/persist";
import * as perspectives from "./modules/perspectives";
import * as players from "./modules/players";
import * as playerVotes from "./modules/player-votes";
import * as rounds from "./modules/rounds";
import * as states from "./modules/states";
import * as suits from "./modules/suits";
import * as topics from "./modules/topics";

export const configureReducers = (history) => combineReducers({
  decks: decks.reducer,
  dashboard: dashboardReducer,
  game: game.reducer,
  intro: intro.reducer,
  login: login.reducer,
  notes: notes.reducer,
  persist: persist.reducer,
  perspectives: perspectives.reducer,
  players: players.reducer,
  playerVotes: playerVotes.reducer,
  rounds: rounds.reducer,
  router: connectRouter(history),
  state: states.reducer,
  suits: suits.reducer,
  topics: topics.reducer,
  gameTimer: gameTimerReducer,
});
