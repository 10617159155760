import { useSelector } from "react-redux";

export const getSelectedByType = ({ state, type = "deck" }) => state.game.selected[type];

export const useGame = () => useSelector((state) => state.game);

export const useGameMode = () => {
  const game = useGame();

  if (!game) {
    return null;
  }

  return game.gameMode;
}