import { createAction } from "redux-actions";

const namespaces = {
  playerVote: "PLAYER-VOTE",
};

export const command = {
  startVote: createAction(`${namespaces.playerVote}/START`),
  endVote: createAction(`${namespaces.playerVote}/END`),
};

export const get = {
  request: createAction(`${namespaces.playerVote}/GET/REQUEST`),
  success: createAction(`${namespaces.playerVote}/GET/SUCCESS`),
  error: createAction(`${namespaces.playerVote}/GET/ERROR`),
};

export const create = {
  request: createAction(`${namespaces.playerVote}/CREATE/REQUEST`),
  success: createAction(`${namespaces.playerVote}/CREATE/SUCCESS`),
  error: createAction(`${namespaces.playerVote}/CREATE/ERROR`),
};

export const terminate = {
  request: createAction(`${namespaces.playerVote}/TERMINATE/REQUEST`),
  success: createAction(`${namespaces.playerVote}/TERMINATE/SUCCESS`),
  error: createAction(`${namespaces.playerVote}/TERMINATE/ERROR`),
};

export const refresh = {
  request: createAction(`${namespaces.playerVote}/REFRESH/REQUEST`),
  stop: createAction(`${namespaces.playerVote}/REFRESH/STOP`),
  cancelled: createAction(`${namespaces.playerVote}/REFRESH/CANCELLED`),
};
