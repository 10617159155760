import * as yup from "yup";
import i18next from "i18next";
import { mapValues } from "lodash";

const t = i18next.getFixedT(null, "modules");

export const fields = {
  actionItem: yup.object({
    name: yup.string().trim().max(255),
    assignee: yup.string().trim().max(127),
    dueAt: yup.string().trim().required(t("game.schemas.shared.required")),
  }),

  deck: yup.string(),
  finding: yup.string().trim().max(1000),
  subject: yup.string().max(1024, t("game.schemas.create.subject.max")),
  team: yup.string(),

  feedback: yup.object({
    open: yup.string(),
    score: yup.number(),
  }),
};

export const create = yup.object({
  deck: fields.deck.required(t("game.schemas.create.deck.required")).nullable(),
  subject: fields.subject.required(t("game.schemas.create.subject.required")),
  team: fields.team.required(t("game.schemas.create.team.required")).nullable(),
});

export const crystallization = {
  create: yup.object({
    actionPlans: yup.lazy((values) => yup.object(
      mapValues(values, () => (
        yup.array().of(fields.actionItem)
      )),
    )),
  }),
};

export const regularExpressions = {
  uuid: "[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}",
};
