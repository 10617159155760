import { uniqBy } from "lodash";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import * as actions from "./actions";
import * as manageReducers from "./manage/reducers";
import * as organizationReducers from "./organization/reducers";

const initialStates = {
  collections: {
    games: {
      finished: [],
      unfinished: [],
      page: 0,
      count: 0,
    },
    search: {
      games: {
        results: [],
        query: "",
      },
    },
    game: null,
    users: [],
    ...manageReducers.initialState.collections,
    ...organizationReducers.initialState.collections,
  },
  status: {
    self: {
      loading: false,
      error: false,
    },
    games: {
      loading: false,
      loaded: false,
      error: false,
    },
    game: {
      loading: false,
      error: false,
      destroyingId: null,
    },
    users: {
      loading: false,
      error: false,
      createError: null,
      creating: false,
      savingId: null,
      destroyingId: null,
      bulkDeletingIds: null,
      patchingUserAttributes: null,
    },
    manage: {
      ...manageReducers.initialState.status,
    },
    organization: {
      ...organizationReducers.initialState.status,
    },
    invite: {
      success: false,
      error: false,
      processing: false,
    },
    resetPassword: {
      success: false,
      error: false,
      processing: false,
    },
    report: {
      success: false,
      error: false,
      processing: false,
    },
    patch: {
      success: false,
      error: false,
      processing: false,
    },
  },
};

const refreshUsersHandler = (state, action) => ({
  ...state,
  users: action.payload,
});

const collections = handleActions(
  {
    [actions.games.clear]: (state) => ({
      ...state,
      games: {
        finished: [],
        unfinished: [],
        page: 0,
        count: 0,
      },
      search: {
        games: {
          results: [],
          query: "",
        },
      },
    }),
    [actions.games.get.success]: (state, action) => {
      const { page, pageSize, pageCount } = action.payload;
      const maxElements = page * pageSize;

      return {
        ...state,
        games: {
          finished: action.payload.page > 0
            ? uniqBy(state.games.finished.concat(action.payload.finishedGames).slice(0, maxElements), "id")
            : action.payload.finishedGames,
          unfinished: action.payload.unfinishedGames,
          count: action.payload.finishedGamesCount,
          page,
          pageSize,
          pageCount,
        },
        gameStats: action.payload.gameStats,
      };
    },
    [actions.games.search.success]: (state, action) => ({
      ...state,
      search: {
        ...state.search,
        games: {
          results: action.payload.results,
          query: action.payload.query,
        },
      },
    }),
    [actions.games.search.clear]: (state) => ({
      ...state,
      search: {
        ...state.search,
        games: {
          results: [],
          query: "",
        },
      },
    }),
    [actions.game.get.request]: (state, action) => ({
      ...state,
      game: action.payload.reload ? state.game : null,
    }),
    [actions.game.get.success]: (state, action) => ({
      ...state,
      game: action.payload,
    }),
    [actions.game.summarize.success]: (state, action) => ({
      ...state,
      game: action.payload.game,
    }),
    [actions.game.patch.success]: (state, action) => ({
      ...state,
      game: action.payload,
    }),
    [actions.game.destroy.success]: (state, action) => ({
      ...state,
      games: {
        ...state.games,
        finished: state.games.finished.filter((g) => g.id !== action.payload.status.gameId),
        unfinished: state.games.unfinished.filter((g) => g.id !== action.payload.status.gameId),
        count: state.games.count - 1,
      },
    }),

    [actions.users.get.success]: refreshUsersHandler,
    [actions.users.post.success]: refreshUsersHandler,
    [actions.users.patch.success]: refreshUsersHandler,
    [actions.users.destroy.success]: refreshUsersHandler,
    [actions.users.bulkDelete.success]: refreshUsersHandler,

    ...manageReducers.collections,
    ...organizationReducers.collections,
  }, initialStates.collections,
);

const status = handleActions(
  {
    [actions.games.clear]: (state) => ({
      ...state,
      games: {
        loading: false,
        loaded: false,
        error: false,
      },
    }),
    // LOADING statuses
    [actions.games.get.request]: (state) => ({
      ...state,
      games: {
        ...state.games,
        loading: true,
        error: false,
      },
    }),
    [actions.games.get.success]: (state) => ({
      ...state,
      games: {
        ...state.games,
        loading: false,
        loaded: true,
        error: false,
      },
    }),
    [actions.games.get.error]: (state) => ({
      ...state,
      games: {
        ...state.games,
        loading: false,
        loaded: false,
        error: true,
      },
    }),

    [actions.game.get.request]: (state) => ({ ...state, game: { ...state.game, loading: true, error: false } }),
    [actions.game.get.success]: (state) => ({ ...state, game: { ...state.game, loading: false, error: false } }),
    [actions.game.get.error]: (state) => ({ ...state, game: { ...state.game, loading: false, error: true } }),

    [actions.users.get.request]: (state) => ({ ...state, users: { ...state.users, loading: true, error: false } }),
    [actions.users.get.success]: (state) => ({ ...state, users: { ...state.users, loading: false, error: false } }),
    [actions.users.get.error]: (state) => ({ ...state, users: { ...state.users, loading: false, error: true } }),

    [actions.users.self.request]: (state) => ({ ...state, self: { ...state.self, loading: true, error: false } }),
    [actions.users.self.success]: (state) => ({ ...state, self: { ...state.self, loading: false, error: false } }),
    [actions.users.self.error]: (state) => ({ ...state, self: { ...state.self, loading: false, error: true } }),

    // CREATE & EDIT statuses

    [actions.game.destroy.request]: (state, action) => ({
      ...state,
      game: { ...state.game, destroyingId: action.payload.gameId },
    }),

    [actions.game.destroy.success]: (state) => ({
      ...state,
      game: { ...state.game, destroyingId: null },
    }),

    [actions.game.destroy.error]: (state) => ({
      ...state,
      game: { ...state.game, destroyingId: null },
    }),

    [actions.users.post.request]: (state) => ({
      ...state,
      users: { ...state.users, creating: true, createError: null },
    }),

    [actions.users.post.success]: (state) => ({
      ...state,
      users: { ...state.users, creating: false },
    }),

    [actions.users.moveUser.request]: (state, action) => ({
      ...state,
      users: { ...state.users, id: action.payload.id },
    }),
    [actions.users.moveUser.error]: (state) => ({
      ...state,
      users: { ...state.users, id: null },
    }),
    [actions.users.moveUser.success]: (state) => ({
      ...state,
      users: { ...state.users, id: null },
    }),

    [actions.users.patch.request]: (state, action) => ({
      ...state,
      users: { ...state.users, savingId: action.payload.id },
    }),

    [actions.users.patch.success]: (state) => ({
      ...state,
      users: { ...state.users, savingId: null },
    }),

    [actions.users.destroy.request]: (state, action) => ({
      ...state,
      users: { ...state.users, destroyingId: action.payload.userId },
    }),

    [actions.users.destroy.success]: (state) => ({
      ...state,
      users: { ...state.users, destroyingId: null },
    }),

    [actions.users.bulkDelete.request]: (state, action) => ({
      ...state,
      users: { ...state.users, bulkDeletingIds: action.payload.ids },
    }),

    [actions.users.bulkDelete.success]: (state) => ({
      ...state,
      users: { ...state.users, bulkDeletingIds: null },
    }),

    // Invite statuses
    [actions.invite.accept.request]: (state) => ({
      ...state,
      invite: { success: false, error: false, processing: true },
    }),
    [actions.invite.accept.success]: (state) => ({
      ...state,
      invite: { success: true, error: false, processing: false },
    }),

    // Reset password statuses
    [actions.password.change.post.request]: (state) => ({
      ...state,
      resetPassword: { success: false, error: false, processing: true },
    }),
    [actions.password.change.post.success]: (state) => ({
      ...state,
      resetPassword: { success: true, error: false, processing: false },
    }),

    // Generate game report statuses
    [actions.game.report.request]: (state) => ({
      ...state,
      report: { success: false, error: false, processing: true },
    }),
    [actions.game.report.success]: (state) => ({
      ...state,
      report: { success: true, error: false, processing: false },
    }),

    // Game patch statuses
    [actions.game.patch.request]: (state) => ({
      ...state,
      patch: { success: false, error: false, processing: true },
    }),
    [actions.game.patch.success]: (state) => ({
      ...state,
      patch: { success: true, error: false, processing: false },
    }),

    // UserAttributes patching
    [actions.users.userAttributes.patch.request]: (state) => ({
      ...state,
      users: { ...state.users, patchingUserAttributes: true },
    }),

    [actions.users.userAttributes.patch.success]: (state) => ({
      ...state,
      users: { ...state.users, patchingUserAttributes: false },
    }),

    [actions.users.userAttributes.patch.error]: (state) => ({
      ...state,
      users: { ...state.users, patchingUserAttributes: false },
    }),

    // ERRORS

    [actions.users.post.error]: (state, action) => ({
      ...state,
      users: { ...state.users, creating: false, createError: action.payload },
    }),
    [actions.invite.accept.error]: (state) => ({
      ...state,
      invite: { success: false, error: true, processing: true },
    }),
    [actions.password.change.post.error]: (state) => ({
      ...state,
      resetPassword: { success: false, error: true, processing: false },
    }),
    [actions.game.report.error]: (state) => ({
      ...state,
      report: { success: false, error: true, processing: false },
    }),
    [actions.game.patch.error]: (state) => ({
      ...state,
      patch: { success: false, error: true, processing: false },
    }),

    // Imported reducers
    ...manageReducers.status,
    ...organizationReducers.status,
  }, initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
