import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { actions } from ".";

const initialStates = {
  collections: {
    serviceMessages: [],
  },
};

const collections = handleActions(
  {
    [actions.serviceMessages.get.success]: (state, action) => ({
      ...state,
      serviceMessages: action.payload,
    }),
  },
  initialStates.collections,
);

export const reducer = combineReducers({
  collections,
});
