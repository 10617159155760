import { compose } from "redux";
import { ConnectedRouter } from "connected-react-router";
import { I18nextProvider } from "react-i18next";
import { Provider, connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

import ReactGA from "./modules/shared/topaasia-ga";

import { i18n } from "./i18n";
import { ApplicationRoutes } from "./routes";
import ApplicationErrorBoundary from "./components/error/ApplicationErrorBoundary";

// Initialize Google Analytics
if (process.env.ENABLE_GA === "true" && process.env.NODE_ENV !== "test") {
  ReactGA.initialize(process.env.GAID, { debug: process.env.NODE_ENV === "dev" });
}

class App extends React.Component {
  render() {
    const { history, store } = this.props;

    return (
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ApplicationErrorBoundary store={store}>
            <ConnectedRouter history={history}>
              <ApplicationRoutes />
            </ConnectedRouter>
          </ApplicationErrorBoundary>
        </I18nextProvider>
        <div id="overlay-container"></div>
      </Provider>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export const Application = compose(
  connect(),
)(App);
