import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { actions } from ".";

const initialStates = {
  byId: {},
  candidates: {
    byRound: {},
  },
  choice: {
    byRound: {},
  },
  status: {
    loading: false,
  },
};

const reduceCandidates = ({ state, action }) => {
  const { round } = action.payload;
  const newState = { byRound: { ...state.byRound } };

  newState.byRound[round.id] = round.candidates;

  return newState;
};

const reduceChoice = ({ state, action }) => {
  const { choice, round } = action.payload;
  const nextState = { ...state };

  if (round && round.id) {
    if (nextState.byRound[round.id] && nextState.byRound[round.id].id === choice.id) {
      delete nextState.byRound[round.id];
    } else {
      nextState.byRound[round.id] = choice;
    }
  }

  return nextState;
};

const byId = handleActions({
  [actions.choice.patch.success]: (state, action) => {
    const { choice, round } = action.payload;
    const nextState = { ...state };

    if (round && round.id) {
      nextState[round.id] = {
        ...state[round.id],
        choice: choice.id,
      };
    }

    return nextState;
  },

  [actions.round.get.success]: (state, action) => {
    const nextState = { ...state };
    nextState[action.payload.id] = action.payload;
    return nextState;
  },

  [actions.current.round.success]: (state, action) => {
    const nextState = { ...state };
    nextState[action.payload.id] = action.payload;
    return state;
  },
}, initialStates.byId);

const candidates = handleActions({
  [actions.candidates.get.success]: (state, action) => reduceCandidates({ state, action }),
  [actions.candidates.pick.success]: (state, action) => {
    const candidate = action.payload;

    const nextState = {
      byRound: {
        ...state.byRound,
        [candidate.roundId]: [
          ...(state.byRound[candidate.roundId] || []), {
            id: candidate.id,
            cardId: candidate.cardId,
            playerId: candidate.playerId,
            playTime: candidate.playTime,
          },
        ],
      },
    };

    return nextState;
  },
  [actions.candidates.remove.success]: (state, action) => {
    const nextState = { ...state };
    const { candidate, round } = action.payload;

    nextState.byRound[round.id] = (nextState.byRound[round.id] || []).filter((c) => (
      c.id !== candidate.id
    ));

    return nextState;
  },
}, initialStates.candidates);

const choice = handleActions({
  [actions.choice.pick]: (state, action) => reduceChoice({ state, action }),
}, initialStates.choice);

const status = handleActions({
  [actions.round.get.request]: () => ({ loading: true }),
  [actions.round.get.success]: () => ({ loading: false }),
  [actions.round.get.error]: () => ({ loading: false }),
}, initialStates.status);

export const reducer = combineReducers({
  byId,
  candidates,
  choice,
  status,
});
