import Jsona from "jsona";

import { backendService } from "../../services";

export const create = async (payload) => {
  const type = "link-requests";
  const url = `/authentication/${type}`;

  const transformed = {
    payload: new Jsona().serialize({
      stuff: {
        type,
        ...payload,
      },
    }),
  };

  return backendService.post({ url, data: transformed.payload });
};
