import { get, without } from "lodash";

export const getById = ({ id, key, state }) => (
  state[key].byId[id]
);

export const getByIds = ({ ids = [], key, state }) => (
  without(ids.map((id) => getById({ id, key, state })), null, undefined)
);

export const getByCollection = ({ collection = "all", key, state }) => {
  const ids = get(state[key], `collections[${collection}]`, []);

  return getByIds({ ids, key, state });
};

export const getByPath = ({ defaultValue = undefined, path, state }) => (
  get(state, path, defaultValue)
);

export const isLoading = ({ key, state }) => (
  state[key].status.loading
);
