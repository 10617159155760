/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { throttle, get } from "lodash";
import { getActionHistory } from "./actionMonitorService";

const ignoredHttpErrorCodes = [401];

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };

    this.logError = throttle(this.logError, 500);

    this.globalErrorHandler = (event) => {
      this.logError(event.error, {
        error_type: "global_error",
      });
    };

    this.loggedErrors = [];
  }

  componentDidMount() {
    window.addEventListener("error", this.globalErrorHandler);
    this.bindAxiosInterceptor();
  }

  componentWillUnmount() {
    window.removeEventListener(this.globalErrorHandler);
  }

  componentDidCatch(error) {
    // Display fallback UI
    this.setState({ hasError: true });
    this.logError(error, {
      error_type: "component_error",
    });
  }

  async logError(error, extraParams) {
    try {
      const { name, message } = error;
  
      const url = "/errors/error-reports";
  
      const {
        getApplicationState = () => {},
        project,
        backendService,
      } = this.props;
  
      const errorCompareId = `${name}, ${message}`;
      
      if (this.loggedErrors.includes(errorCompareId)) {
        // Skip errors that are probably the same error repeated.
        return;
      }
  
      this.loggedErrors.push(errorCompareId);
  
      await backendService.post({
        url,
        data: {
          name,
          message,
          application_state: getApplicationState(),
          action_history: getActionHistory(),
          url: window.location.href,
          project,
          ...extraParams,
        },
      });
    } catch {
      // no op - if the reporting itself fails there is nothing we can do
    }
  }

  render() {
    const { title, message } = this.props;

    if (this.state.hasError) {
      return (
        <div className="error-boundary-container">
          <div className="error-boundary-content">
            <h1>{title}</h1>
            <p>{message}</p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }

  bindAxiosInterceptor() {
    const {
      axios,
      networkErrorFilter = () => true,
    } = this.props;

    // Intercept requests to log any errors encountered
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (networkErrorFilter(error)) {
          const responseURL = get(error, "request.responseURL", null);
          const response = get(error, "request.response", {});
          const statusCode = get(error, "request.status", 0);
          const shouldIgnore = statusCode < 400 || ignoredHttpErrorCodes.includes(statusCode);

          // Prevent endless recursion of error reports by not reporting
          // the failure to send the report.
          if (!shouldIgnore && responseURL && !responseURL.includes("/error-reports")) {
            this.logError(error, {
              response_url: responseURL,
              response,
              error_type: "network_error",
            });
          }
        }

        return Promise.reject(error);
      },
    );
  }
}

ErrorBoundary.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  axios: PropTypes.func.isRequired,
  getApplicationState: PropTypes.func,
  shouldReportNetworkError: PropTypes.func,
  children: PropTypes.any,
  project: PropTypes.string.isRequired,
  backendService: PropTypes.shape({
    post: PropTypes.func.isRequired,
  }).isRequired,
};

export default ErrorBoundary;
