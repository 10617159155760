import { call, put, take, retry } from "redux-saga/effects";
import { push } from "connected-react-router";

import * as actions from "../actions";
import { gamesApi as api } from "..";
import { organizationService } from "../../../services";

export const games = {
  * get(action) {
    try {
      const organizationId = organizationService.getCurrent();

      if (!organizationId) {
        throw Error("Cannot get games for undefined organization");
      }

      const { page, pageSize } = action.payload;
      const response = yield retry(3, 1000, api.list, { organizationId, page, pageSize });
      const payload = response ? response.data : {};

      yield put(actions.games.get.success({
        finishedGames: payload.finishedGames,
        unfinishedGames: payload.unfinishedGames,
        finishedGamesCount: payload.finishedGamesCount,
        page: payload.page,
        pageSize: payload.pageSize,
        pageCount: payload.pageCount,
        gameStats: payload.gameStats,
      }));
    } catch (error) {
      yield put(actions.games.get.error(error));
      throw error;
    }
  },

  * search(action) {
    try {
      const organizationId = organizationService.getCurrent();
      const { searchQuery } = action.payload;

      if (!searchQuery || searchQuery === "") {
        yield put(actions.games.search.clear());
        return;
      }

      const response = yield call(api.search, { organizationId, searchQuery });
      const payload = response ? response.data : {};

      yield put(actions.games.search.success({
        results: payload.results,
        query: payload.query,
      }));
    } catch (error) {
      yield put(actions.games.search.error(error));
      throw error;
    }
  },
};

export const game = {
  * get(action) {
    try {
      const { gameId } = action.payload;
      const response = yield call(api.get, { gameId });
      const payload = response ? response.data : {};

      yield put(actions.game.get.success(payload));
    } catch (error) {
      yield put(actions.game.get.error(error));
    }
  },

  * destroy(action) {
    try {
      const { gameId, afterDestroy = async () => {} } = action.payload;
      const response = yield call(api.destroy, { gameId });
      const payload = response ? response.data : {};

      yield put(actions.game.destroy.success(payload));

      yield afterDestroy();
    } catch (error) {
      yield put(actions.game.destroy.error(error));
    }
  },

  * patch(action) {
    try {
      const gameId = action.payload.id;
      const { visibility } = action.payload;
      const response = yield call(api.patch, { gameId, visibility });
      const payload = response ? response.data : {};

      yield put(actions.game.patch.success(payload));
    } catch (error) {
      yield put(actions.game.patch.error(error));
    }
  },

  * destroyAndPush(action) {
    yield put(actions.game.destroy.request({ gameId: action.payload.gameId }));
    yield take(actions.game.destroy.success);
    yield put(push(action.payload.route));
  },

  * report(action) {
    try {
      const { gameId, userId } = action.payload;
      const response = yield call(api.report, { gameId, userId });
      const payload = response ? response.data : {};

      yield put(actions.game.report.success(payload));
    } catch (error) {
      yield put(actions.game.report.error(error));
    }
  },

  * summarize(action) {
    try {
      const { id, onCompletion = () => {} } = action.payload;
      const response = yield call(api.summarize, { id });
      const payload = response ? response.data : {};
  
      yield put(actions.game.summarize.success(payload));

      onCompletion({ success: true });
    } catch (error) {
      const { onCompletion = () => {} } = action.payload;
      yield put(actions.game.summarize.error(error));
      onCompletion({ success: false });
    }
  },
};
