import { backendService } from "../../services";
import { utils } from "../shared";

export const get = async () => {
  const response = await backendService.get({
    url: `/service_messages/`,
  });

  return { data: utils.fromSnakeToCamel(response.data.service_messages) };
};
