import { createAction } from "redux-actions";

const namespace = "LINK-REQUESTS";

export const post = {
  request: createAction(
    `${namespace}/POST/REQUEST`,
    (payload) => payload.values,
    (payload) => payload.meta,
  ),
  success: createAction(`${namespace}/POST/SUCCESS`),
  error: createAction(`${namespace}/POST/ERROR`),
};
