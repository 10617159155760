import Jsona from "jsona";

import { backendService } from "../../services";

const roundResponseToData = (response) => ({
  id: response.data.id,
  chosenCandidateId: response.data.chosen_candidate_id,
  perspectiveId: response.data.perspective_id,
  roundState: response.data.round_state,
  playOrder: response.data.play_order,
});

export const candidates = {
  get: async (payload) => {
    const { round } = payload;
    const url = `/rounds/${round.id}/relationships/candidates`;

    const response = await backendService.get({ url });

    return {
      ...response,
      data: {
        round: {
          id: round.id,
          candidates: response.data.candidates.map((c) => ({
            id: c.id,
            cardId: c.card_id,
            playerId: c.player_id,
            playTime: c.created_at,
          })),
        },
      },
    };
  },

  pick: async (payload) => {
    const { round } = payload;
    const url = `/rounds/${round.id}/relationships/candidates`;

    const data = {
      card_id: payload.card.id,
    };

    const response = await backendService.post({ url, data });

    return {
      data: {
        id: response.data.id,
        roundId: response.data.round_id,
        cardId: response.data.card_id,
        playerId: response.data.player_id,
        playTime: response.data.created_at,
      },
    };
  },

  remove: async (payload) => {
    const { round } = payload;
    const url = `/rounds/${round.id}/relationships/candidates/${payload.candidate.id}`;

    await backendService.destroy({ url });
  },

  submit: async (payload) => {
    const { round } = payload;
    const url = `/rounds/${round.id}/end_picking`;

    const response = await backendService.patch({ url });

    const transformed = {
      response: {
        ...response,
        data: new Jsona().deserialize(response.data),
      },
    };

    return transformed.response;
  },

  skip: async (payload) => {
    const { round } = payload;
    const url = `/rounds/${round.id}/skip_round`;

    const response = await backendService.patch({ url });

    const transformed = {
      response: {
        ...response,
        data: new Jsona().deserialize(response.data),
      },
    };

    return transformed.response;
  },
};

export const choice = {
  patch: async (payload) => {
    const { round } = payload;
    const candidateId = payload.choice.id;
    const url = `/rounds/${round.id}/relationships/candidates/choose`;

    const data = {
      candidate_id: candidateId,
    };

    const response = await backendService.patch({ url, data });

    return {
      ...response,
      data: {
        choice: payload.choice,
        round: {
          id: round.id,
        },
      },
    };
  },
};

export const round = {
  get: async (id) => {
    const response = await backendService.get({ url: `/rounds/${id}` });

    const transformed = {
      response: {
        ...response,
        data: roundResponseToData(response),
      },
    };

    return transformed.response;
  },
  current: async (gameId) => {
    const url = `/games/${gameId}/relationships/rounds/current_round`;
    const response = await backendService.get({ url });

    const transformed = {
      response: {
        ...response,
        data: roundResponseToData(response),
      },
    };

    return transformed.response;
  },
};
