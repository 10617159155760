import * as yup from "yup";
import i18next from "i18next";

const t = i18next.getFixedT(null, "modules");

export const fields = {
  email: yup.string().email(t("link-requests.schemas.email.format")),
};

export const create = yup.object({
  email: fields.email.required(t("link-requests.schemas.email.required")),
});
