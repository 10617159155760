import { createAction } from "redux-actions";

const namespaces = {
  persist: "PERSIST",
  instructions: "INSTRUCTIONS",
};

export const instructions = {
  markAsRead: createAction(`${namespaces.persist}/${namespaces.instructions}/MARK_AS_READ`),
  clear: createAction(`${namespaces.persist}/${namespaces.instructions}/CLEAR_INSTRUCTIONS_READ`),
};
