import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { actions } from ".";

const initialState = {
  collections: {
    activeVote: null,
  },
  status: {
    loading: false,
    creatingVote: false,
    terminatingVote: false,
    terminatedVoteIds: [],
  },
};

const collections = handleActions({
  [actions.get.success]: (state, action) => {
    const { payload } = action;

    return {
      activeVote: { poll: payload.poll, votes: payload.votes },
    };
  },
  [actions.create.success]: (state, action) => {
    const { payload } = action;

    return {
      activeVote: { poll: payload, votes: [] },
    };
  },
  [actions.terminate.success]: (state, action) => {
    const { payload } = action;

    return {
      activeVote: { poll: payload, votes: state.activeVote.votes },
    };
  },
}, initialState.collections);

const status = handleActions({
  [actions.get.request]: (state) => ({ ...state, loading: true }),
  [actions.get.success]: (state) => ({ ...state, loading: false }),
  [actions.get.error]: (state) => ({ ...state, loading: false }),

  [actions.create.request]: (state) => ({ ...state, creatingVote: true }),
  [actions.create.success]: (state) => ({ ...state, creatingVote: false }),
  [actions.create.error]: (state) => ({ ...state, creatingVote: false }),

  [actions.terminate.request]: (state) => ({ ...state, terminatingVote: true }),
  [actions.terminate.success]: (state, action) => ({
    ...state,
    terminatingVote: false,
    terminatedVoteIds: [...state.terminatedVoteIds, action.payload.id],
  }),
  [actions.terminate.error]: (state) => ({ ...state, terminatingVote: false }),
}, initialState.status);

export const reducer = combineReducers({
  collections,
  status,
});
