import { createAction } from "redux-actions";

const namespaces = {
  candidates: "CANDIDATES",
  choice: "CHOICE",
  current: "CURRENT",
  round: "ROUND",
  rounds: "ROUNDS",
};

export const candidates = {
  patch: {
    request: createAction(`${namespaces.round}/${namespaces.candidates}/PATCH/REQUEST`),
    success: createAction(`${namespaces.round}/${namespaces.candidates}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.round}/${namespaces.candidates}/PATCH/ERROR`),
  },
  get: {
    request: createAction(`${namespaces.round}/${namespaces.candidates}/GET/REQUEST`),
    success: createAction(`${namespaces.round}/${namespaces.candidates}/GET/SUCCESS`),
    error: createAction(`${namespaces.round}/${namespaces.candidates}/GET/ERROR`),
  },
  refresh: {
    request: createAction(`${namespaces.round}/${namespaces.candidates}/REFRESH/REQUEST`),
    stop: createAction(`${namespaces.round}/${namespaces.candidates}/REFRESH/STOP`),
    cancelled: createAction(`${namespaces.round}/${namespaces.candidates}/REFRESH/CANCELLED`),
  },
  pick: {
    request: createAction(`${namespaces.round}/${namespaces.candidates}/PICK/REQUEST`),
    success: createAction(`${namespaces.round}/${namespaces.candidates}/PICK/SUCCESS`),
    error: createAction(`${namespaces.round}/${namespaces.candidates}/PICK/ERROR`),
  },
  remove: {
    request: createAction(`${namespaces.round}/${namespaces.candidates}/REMOVE/REQUEST`),
    success: createAction(`${namespaces.round}/${namespaces.candidates}/REMOVE/SUCCESS`),
    error: createAction(`${namespaces.round}/${namespaces.candidates}/REMOVE/ERROR`),
  },
};

export const choice = {
  patch: {
    request: createAction(`${namespaces.round}/${namespaces.choice}/PATCH/REQUEST`),
    success: createAction(`${namespaces.round}/${namespaces.choice}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.round}/${namespaces.choice}/PATCH/ERROR`),
  },
  pick: createAction(`${namespaces.round}/${namespaces.choice}/PICK`),
};

export const current = {
  round: {
    request: createAction(`${namespaces.rounds}/${namespaces.current}/ROUND/REQUEST`),
    success: createAction(`${namespaces.rounds}/${namespaces.current}/ROUND/SUCCESS`),
    error: createAction(`${namespaces.rounds}/${namespaces.current}/ROUND/ERROR`),
  },
};

export const round = {
  get: {
    request: createAction(`${namespaces.round}/GET/REQUEST`),
    success: createAction(`${namespaces.round}/GET/SUCCESS`),
    error: createAction(`${namespaces.round}/GET/ERROR`),
  },
};
