import { takeLatest } from "redux-saga/effects";

import * as actions from "./actions";
import * as gameSagas from "./games/sagas";
import * as userSagas from "./users/sagas";
import * as manageSagas from "./manage/sagas";
import * as organizationSagas from "./organization/sagas";

// Will be combined to the root saga in app/sagas.js
export default {
  sagas: [
    takeLatest(actions.games.get.request, gameSagas.games.get),
    takeLatest(actions.games.search.request, gameSagas.games.search),
    takeLatest(actions.game.get.request, gameSagas.game.get),
    takeLatest(actions.game.destroy.request, gameSagas.game.destroy),
    takeLatest(actions.game.destroy.requestWithRedirect, gameSagas.game.destroyAndPush),
    takeLatest(actions.game.report.request, gameSagas.game.report),
    takeLatest(actions.game.summarize.request, gameSagas.game.summarize),
    takeLatest(actions.game.patch.request, gameSagas.game.patch),

    takeLatest(actions.users.get.request, userSagas.users.get),
    takeLatest(actions.users.post.request, userSagas.users.post),
    takeLatest(actions.users.moveUser.request, userSagas.users.moveUser),
    takeLatest(actions.users.patch.request, userSagas.users.patch),
    takeLatest(actions.users.destroy.request, userSagas.users.destroy),
    takeLatest(actions.users.bulkDelete.request, userSagas.users.bulkDelete),
    takeLatest(actions.users.self.request, userSagas.users.self),
    takeLatest(actions.users.userAttributes.patch.request, userSagas.userAttributes.patch),

    takeLatest(actions.manage.organization.patch.request, manageSagas.manage.organization.patch),
    takeLatest(actions.manage.subscriptionProducts.current.request, manageSagas.manage.subscriptionProducts.current),
    takeLatest(actions.manage.subscriptionProducts.list.request, manageSagas.manage.subscriptionProducts.list),
    takeLatest(actions.manage.subscriptionProducts.byId.request, manageSagas.manage.subscriptionProducts.byId),
    takeLatest(actions.manage.subscription.update.preview.request, manageSagas.manage.subscription.update.preview),
    takeLatest(actions.manage.subscription.cancel.request, manageSagas.manage.subscription.cancel),
    takeLatest(actions.manage.paymentMethods.list.request, manageSagas.manage.paymentMethods.list),
    takeLatest(actions.manage.purchasableProducts.list.request, manageSagas.manage.purchasableProducts.list),
    takeLatest(actions.manage.purchasableProducts.purchased.request, manageSagas.manage.purchasableProducts.purchased),
    takeLatest(actions.manage.purchasableProducts.consume.request, manageSagas.manage.purchasableProducts.consume),
    takeLatest(actions.manage.organizationEffects.own.request, manageSagas.manage.organizationEffects.own),

    takeLatest(actions.organization.getOrganizationUmbrella.request, organizationSagas.organization.getOrganizationUmbrella),
    takeLatest(actions.organization.get.request, organizationSagas.organization.get),
    takeLatest(actions.organization.list.request, organizationSagas.organization.list),
    takeLatest(actions.organization.change.request, organizationSagas.organization.change),
    takeLatest(actions.organization.destroy.prepare.request, organizationSagas.organization.prepareDestroy),
    takeLatest(actions.organization.destroy.request, organizationSagas.organization.destroy),

    takeLatest(actions.invite.accept.request, userSagas.invite.accept),

    takeLatest(actions.password.change.init.request, userSagas.password.init),
    takeLatest(actions.password.change.post.request, userSagas.password.post),
  ],
};
