import { uniqBy } from "lodash";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { gameNoteActions } from "./actions";

const initialState = {
  collections: {
    gameNotes: [],
  },
  ui: {
    isOpen: false,
  },
  status: {
    loading: false,
    updating: false,
    dirty: false,
    error: false,
  },
};

const collections = handleActions({
  [gameNoteActions.get.success]: (_, action) => {
    const { gameNotes } = action.payload;
    return { gameNotes };
  },
  [gameNoteActions.patch.success]: (state, action) => {
    const { gameNotes } = action.payload;
    const stateNotes = state.gameNotes;

    const replacedNotes = stateNotes.map((gameNote) => {
      const replacement = gameNotes.find((n) => n.id === gameNote.id);
      return replacement || gameNote;
    });

    const newNotes = uniqBy([...replacedNotes, ...gameNotes], "id");

    return {
      gameNotes: newNotes,
    };
  },
}, initialState.collections);

const ui = handleActions({
  [gameNoteActions.ui.openNotes]: () => ({ isOpen: true }),
  [gameNoteActions.ui.closeNotes]: () => ({ isOpen: false }),
}, initialState.ui);

const status = handleActions({
  [gameNoteActions.patch.request]: (state) => ({ ...state, updating: true }),
  [gameNoteActions.patch.success]: (state) => ({ ...state, updating: false, dirty: false, error: false }),
  [gameNoteActions.patch.error]: (state) => ({ ...state, updating: false, error: true }),
  [gameNoteActions.get.request]: (state) => ({ ...state, loading: true }),
  [gameNoteActions.get.success]: (state) => ({ ...state, loading: false, error: false }),
  [gameNoteActions.get.error]: (state) => ({ ...state, loading: false, error: true }),
}, initialState.status);

export const reducer = combineReducers({
  collections,
  ui,
  status,
});
