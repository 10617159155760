/* eslint-disable no-param-reassign */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */

import _, { maxBy } from "lodash";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { take, fork, cancel } from "redux-saga/effects";
import useResizeObserver from "@react-hook/resize-observer";

export const fromSnakeToCamel = (data) => {
  if (_.isArray(data)) {
    return _.map(data, fromSnakeToCamel);
  }

  if (_.isObject(data)) {
    return _(data)
      .mapKeys((v, k) => _.camelCase(k))
      .mapValues((v) => fromSnakeToCamel(v))
      .value();
  }

  return data;
};

export const fromCamelToSnake = (data) => {
  if (_.isArray(data)) {
    return _.map(data, fromCamelToSnake);
  }

  if (_.isObject(data)) {
    return _(data)
      .mapKeys((v, k) => _.snakeCase(k))
      .mapValues((v) => fromCamelToSnake(v))
      .value();
  }

  return data;
};

export const mulberry32 = (a) => () => {
  // eslint-disable-next-line no-multi-assign
  let t = a += 0x6D2B79F5;
  t = Math.imul(t ^ t >>> 15, t | 1);
  // eslint-disable-next-line no-bitwise
  t ^= t + Math.imul(t ^ t >>> 7, t | 61);
  return ((t ^ t >>> 14) >>> 0) / 4294967296;
};

// Simple hash generator. This is used to seed random number generators with the game id.
export const hashCode = (s) => s.split("").reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);

export function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
}

// A hook that is somewhat equivalent to componentDidUpdate.
// It will not call the callback on first render.
export function useEffectUpdate(callback, props) {
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // toggle flag after first render/mounting
      return;
    }
    callback(); // performing action after state has updated
  }, props);
}

// A hook that allows access to a previous value of a state or prop
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useForceUpdate(){
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(0); // integer state
  return () => setValue((v) => v + 1); // update the state to force render
}

export const useSize = (target) => {
  const [size, setSize] = useState();

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

export const useFirstRender = () => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  return isFirstRender.current;
};

export const useViewportSize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const onResize = useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return [width, height];
};

// This hook can be used by components that call async functions
// and want to ensure the component is not unmounted before updating state
// with the results.
export const useCancelled = () => {
  const isCancelled = useRef(false);

  useEffect(() => {
    isCancelled.current = false;
    return () => { isCancelled.current = true; };
  }, []);

  return isCancelled;
};

// A Redux saga effect that isolates takeLatest with an id so it only cancels running tasks
// with the same id, and not all sagas of the same pattern.
// eslint-disable-next-line func-names
export const takeLatestDeep = (pattern, identifier, fn, ...args) => fork(function* () {
  const tasks = {};

  while (true) {
    const action = yield take(pattern);
    const id = identifier(action);

    if (tasks[id]) {
      yield cancel(tasks[id]);
    }

    tasks[id] = yield fork(fn, ...args.concat(action));
  }
});

// Measures text block height with Canvas when the block width is known.
// You should pass font information into the context before calling this.
export function measureTextBlockHeight(context, text, lineHeight, maxWidth) {
  const fitWidth = maxWidth || 0;

  if (fitWidth <= 0) {
    return { height: 0, lines: [], longestLineWidth: 0 };
  }

  const lines = [];
  let words = text.split(" ");
  let wordIndex = 1;

  while (words.length > 0 && wordIndex <= words.length) {
    const str = words.slice(0, wordIndex).join(" ");
    const w = context.measureText(str).width;

    if (w > fitWidth) {
      if (wordIndex === 1) {
        wordIndex = 2;
      }

      lines.push(words.slice(0, wordIndex - 1));

      words = words.splice(wordIndex - 1);
      wordIndex = 1;
    } else {
      wordIndex += 1;
    }
  }

  lines.push(words);
  const joinedLines = lines.map((l) => l.join(" "));

  return {
    height: lines.length * lineHeight,
    lines: joinedLines,
    longestLineWidth: context.measureText(maxBy(joinedLines, (l) => l.length)).width,
  };
}

export function limitWhitespace(string) {
  return string.replace(/\s+/g, " ");
}

// A function that clones an array and replaces one item in it.
// It is often useful in immutable state handling with arrays.
export function cloneAndInsert(originalArray, newItemTransform, findPredicate) {
  const index = originalArray.findIndex(findPredicate);
  const item = originalArray[index];

  const newArray = Array.from(originalArray);
  newArray.splice(index, 1, newItemTransform(item));

  return newArray;
}
