import { handleActions } from "redux-actions";
import { get, pick, xor, isEqual, flatten } from "lodash";

import { actions } from ".";
import * as rounds from "../rounds";

const initialState = {
  id: null,
  conclusions: [],
  playerParticipations: {
    collection: [],
    active: null,
  },
  current: {
    round: null,
  },
  rounds: [],
  selected: {
    deck: {
      id: null,
      language: "fi",
    },
    perspectives: [],
    team: null,
    topaasia: null,
  },
  subject: null,
  state: null,
  status: {
    loading: false,

    // Patching status payload
    // An array of game patch actions
    // [{
    //   id: "{uuid}",  # Id of the affected record (with findings, its the topicId)
    //   status: "dirty" | "create" | "patch" | "destroy",
    //   type: "finding" | "actionItem" | "feedback" | "playerParticipation"
    // }
    patching: [],
    errors: [],
  },
  topics: [],
  feedback: {},
  gameCode: null,
  gameMaster: null,
};

const conclusionPicker = (conclusion) => ({
  ...pick(conclusion, ["id", "items", "report", "type", "updatedAt"]),
  topicId: get(conclusion, "topic.id") || get(conclusion, "topic") || get(conclusion, "topicId"), // Depends on the serializer used
});

const reduceGame = ({ state, action }) => {
  const {
    conclusions = [],
    deck,
    id,
    perspectives = [],
    subject,
    team,
    gameMode,
    topaasiaRound,
    topics = [],
    playerParticipations = [],
  } = action.payload;
  const nextState = { ...state };

  const topaasiaRoundId = (topaasiaRound || {}).id || null;

  nextState.conclusions = conclusions.map((conclusion) => conclusionPicker(conclusion));
  nextState.playerParticipations = {
    collection: playerParticipations,
    active: playerParticipations.find((pp) => pp.state === "active") || null,
  };
  nextState.id = id;
  nextState.rounds = get(action, "payload.rounds", []).map((round) => round.id);
  nextState.subject = subject;
  nextState.gameMode = gameMode;
  nextState.selected.deck = {
    ...deck,
    cards: flatten(deck.suits.map((s) => s.cards)),
  };
  nextState.selected.perspectives = perspectives.map((perspective) => perspective.id);
  nextState.selected.team = team.id;
  nextState.selected.topaasia = { round: topaasiaRoundId };
  nextState.state = get(action, "payload.state");
  nextState.status.loading = false;
  nextState.topics = topics.map((topic) => topic.id);
  nextState.gameCode = get(action, "payload.gameCode");
  nextState.gameCodeClosed = get(action, "payload.gameCodeClosed");
  nextState.enablePlayerGameNotes = get(action, "payload.enablePlayerGameNotes", false);
  nextState.gameMaster = get(action, "payload.gameMasterEmail");
  nextState.feedback = get(action, "payload.feedback");

  return nextState;
};

const reduceRound = ({ action }) => ({
  id: action.payload.id,
  roundState: action.payload.roundState,
  perspectiveId: action.payload.perspectiveId,
  playOrder: action.payload.playOrder,
});

const addingStatusAction = (state, action) => ({
  ...state.status,
  patching: [...state.status.patching, action],
});

const removingStatusAction = (state, action) => ({
  ...state.status,
  patching: state.status.patching.filter((a) => (
    !isEqual([a.id, a.status, a.type], [action.id, action.status, action.type])
  )),
});

const raisingErrors = (state, errorPayload) => ({
  ...state,
  errors: [...state.errors, errorPayload],
});

const clearingErrors = (state, type) => ({
  ...state,
  errors: state.errors.filter((e) => e.type !== type),
});

const insertingPlayerParticipation = (state, playerParticipation) => {
  const existingIndex = state.playerParticipations
    .collection.findIndex((pp) => pp.id === playerParticipation.id);

  const newCollection = Array.from(state.playerParticipations.collection);

  if (existingIndex !== -1) {
    newCollection.splice(existingIndex, 1, playerParticipation);
  } else {
    newCollection.push(playerParticipation);
  }

  return {
    collection: newCollection,
    active: newCollection.find((pp) => pp.state === "active") || null,
  };
};

const removingPlayerIdea = (state, playerIdeaId) => {
  const playerParticipationIndex = state.playerParticipations.collection.findIndex(
    (pp) => pp.playerIdeas.findIndex((pi) => pi.id === playerIdeaId) !== -1,
  )

  const playerParticipation = state.playerParticipations.collection[playerParticipationIndex];
  const newPlayerParticipation = {
    ...playerParticipation,
    playerIdeas: playerParticipation.playerIdeas.filter((pi) => pi.id !== playerIdeaId),
  };

  const newCollection = Array.from(state.playerParticipations.collection);
  newCollection.splice(playerParticipationIndex, 1, newPlayerParticipation);

  return {
    collection: newCollection,
    active: newCollection.find((pp) => pp.state === "active") || null,
  };
};

export const reducer = handleActions({
  [actions.deck.change]: (state, action) => ({
    ...state,
    selected: {
      ...state.selected,
      deck: {
        ...state.selected.deck,
        id: action.payload.id,
      },
      // Reset the selected perspectives only, if the deck ID changes
      perspectives: state.selected.deck.id === action.payload.id
        ? state.selected.perspectives
        : [],
    },
  }),

  [actions.findings.post.request]: (state, action) => ({
    ...state,
    status: addingStatusAction(state, {
      id: action.payload.topicId,
      status: "patch",
      type: "finding",
    }),
  }),

  [actions.findings.post.success]: (state, action) => {
    const oldConclusions = state.conclusions;
    const conclusion = conclusionPicker(action.payload);
    const existingConclusionIndex = oldConclusions.findIndex((c) => c.id === conclusion.id);
    const newConclusions = [...oldConclusions];

    if (existingConclusionIndex >= 0) {
      const existingConclusionObject = oldConclusions[existingConclusionIndex];

      if (existingConclusionObject.updatedAt <= conclusion.updatedAt) {
        newConclusions.splice(existingConclusionIndex, 1, conclusion);
      }
    } else {
      newConclusions.push(conclusion);
    }

    return {
      ...state,
      conclusions: newConclusions,
      status: clearingErrors(removingStatusAction(state, {
        id: conclusion.topicId,
        status: "patch",
        type: "finding",
      }), "finding"),
    };
  },

  [actions.findings.post.error]: (state, action) => ({
    ...state,
    status: raisingErrors(state.status, {
      type: "finding",
      code: get(action.payload, "response.status"),
    }),
  }),

  [actions.findings.post.dirty]: (state, action) => {
    const { topicId, isDirty } = action.payload;

    if (isDirty) {
      return {
        ...state,
        status: addingStatusAction(state, {
          id: topicId,
          status: "dirty",
          type: "finding",
        }),
      };
    }

    return {
      ...state,
      status: removingStatusAction(state, {
        id: topicId,
        status: "dirty",
        type: "finding",
      }),
    };
  },

  [actions.actionPlan.dirty]: (state, action) => {
    const { topicId, isDirty } = action.payload;

    if (isDirty) {
      return {
        ...state,
        status: addingStatusAction(state, {
          id: topicId,
          status: "dirty",
          type: "actionPlan",
        }),
      };
    }

    return {
      ...state,
      status: removingStatusAction(state, {
        id: topicId,
        status: "dirty",
        type: "actionPlan",
      }),
    };
  },

  [actions.actionItems.post.request]: (state) => ({
    ...state,
    status: addingStatusAction(state, {
      id: "",
      status: "create",
      type: "actionItem",
    }),
  }),

  [actions.actionItems.post.error]: (state, action) => ({
    ...state,
    status: raisingErrors(removingStatusAction(state, {
      id: "",
      status: "create",
      type: "actionItem",
    }), {
      type: "actionItemCreate",
      code: get(action.payload, "response.status"),
    }),
  }),

  [actions.actionItems.post.clearError]: (state) => ({
    ...state,
    status: clearingErrors(state.status, "actionItemCreate"),
  }),

  [actions.actionItems.post.success]: (state, action) => {
    // Needs some gymnastics to retain the original order without mutating the old state.
    const { actionItem } = action.payload;

    // ActionPlan can either already exist or be just created - handle both scenarios
    const actionPlan = state.conclusions.find((c) => c.id === actionItem.actionPlanId) || action.payload.actionPlan;
    const index = state.conclusions.indexOf(actionPlan);
    let newActionPlan;

    if (index >= 0) {
      newActionPlan = {
        ...actionPlan,
        items: [
          ...((actionPlan && actionPlan.items) || []),
          actionItem,
        ],
      };
    } else {
      newActionPlan = {
        id: actionPlan.id,
        topicId: actionPlan.topicId,
        type: "ActionPlan",
        items: [actionItem],
      };
    }

    const newConclusions = Array.from(state.conclusions);

    if (index >= 0) {
      newConclusions.splice(index, 1, newActionPlan);
    } else {
      newConclusions.push(newActionPlan);
    }

    return {
      ...state,
      conclusions: newConclusions,
      status: removingStatusAction(state, {
        id: "",
        status: "create",
        type: "actionItem",
      }),
    };
  },

  [actions.actionItems.destroy.request]: (state, action) => ({
    ...state,
    status: addingStatusAction(state, {
      id: action.payload.id,
      status: "destroy",
      type: "actionItem",
    }),
  }),

  [actions.actionItems.destroy.error]: (state, action) => ({
    ...state,
    status: raisingErrors(removingStatusAction(state, {
      id: action.payload.id,
      status: "destroy",
      type: "actionItem",
    }), {
      type: "actionItemDestroy",
      code: get(action.payload, "response.status"),
    }),
  }),

  [actions.actionItems.destroy.clearError]: (state) => ({
    ...state,
    status: clearingErrors(state.status, "actionItemDestroy"),
  }),

  [actions.actionItems.destroy.success]: (state, action) => {
    // Find the ActionPlan that contains the item with the deleted id. A bit messy.
    const itemId = action.payload.id;
    const actionPlan = state.conclusions.find((c) => c.items && c.items.map((i) => i.id).includes(itemId));
    const index = state.conclusions.indexOf(actionPlan);

    const newActionPlan = {
      ...actionPlan,
      items: actionPlan.items.filter((i) => i.id !== itemId),
    };

    const newConclusions = Array.from(state.conclusions);
    newConclusions.splice(index, 1, newActionPlan);

    return {
      ...state,
      conclusions: newConclusions,
      status: removingStatusAction(state, {
        id: itemId,
        status: "destroy",
        type: "actionItem",
      }),
    };
  },

  [actions.actionItems.patch.request]: (state, action) => ({
    ...state,
    status: addingStatusAction(state, {
      id: action.payload.id,
      status: "patch",
      type: "actionItem",
    }),
  }),

  [actions.actionItems.patch.error]: (state, action) => ({
    ...state,
    status: raisingErrors(state.status, {
      type: "actionItem",
      code: get(action.payload, "response.status"),
    }),
  }),

  [actions.actionItems.patch.success]: (state, action) => {
    const itemId = action.payload.id;
    const actionPlan = state.conclusions.find((c) => c.items && c.items.map((i) => i.id).includes(itemId));
    const conclusionIndex = state.conclusions.indexOf(actionPlan);
    const actionItemIndex = actionPlan.items.findIndex((item) => item.id === itemId);

    const newActionItems = Array.from(actionPlan.items);
    newActionItems.splice(actionItemIndex, 1, { ...action.payload });

    const newActionPlan = {
      ...actionPlan,
      items: newActionItems,
    };

    const newConclusions = Array.from(state.conclusions);
    newConclusions.splice(conclusionIndex, 1, newActionPlan);

    return {
      ...state,
      conclusions: newConclusions,
      status: clearingErrors(removingStatusAction(state, {
        id: itemId,
        status: "patch",
        type: "actionItem",
      }), "actionItem"),
    };
  },

  [actions.playerParticipations.list.success]: (state, action) => ({
    ...state,
    playerParticipations: {
      collection: action.payload,
      active: action.payload.find((pp) => pp.state === "active"),
    },
  }),

  [actions.playerParticipations.get.success]: (state, action) => ({
    ...state,
    playerParticipations: insertingPlayerParticipation(state, action.payload),
  }),

  [actions.playerParticipations.post.request]: (state, action) => ({
    ...state,
    status: addingStatusAction(state, {
      id: "",
      status: "create",
      type: "playerParticipation",
      payload: {
        topicId: action.payload.topicId,
      },
    }),
  }),

  [actions.playerParticipations.post.success]: (state, action) => ({
    ...state,
    playerParticipations: insertingPlayerParticipation(state, action.payload),
    status: removingStatusAction(state, {
      id: "", status: "create", type: "playerParticipation",
    }),
  }),

  [actions.playerParticipations.patch.request]: (state, action) => ({
    ...state,
    status: addingStatusAction(state, {
      id: action.payload.playerParticipationId,
      status: "patch",
      type: "playerParticipation",
    }),
  }),

  [actions.playerParticipations.patch.success]: (state, action) => ({
    ...state,
    playerParticipations: insertingPlayerParticipation(state, action.payload),
    status: removingStatusAction(state, {
      id: action.payload.id,
      status: "patch",
      type: "playerParticipation",
    }),
  }),

  [actions.playerIdeas.destroy.request]: (state, action) => ({
    ...state,
    status: addingStatusAction(state, {
      id: action.payload.playerIdeaId,
      status: "destroy",
      type: "playerIdea",
    }),
  }),

  [actions.playerIdeas.destroy.success]: (state, action) => ({
    ...state,
    playerParticipations: removingPlayerIdea(state, action.payload.id),
    status: removingStatusAction(state, {
      id: action.payload.id,
      status: "destroy",
      type: "playerIdea",
    }),
  }),

  [actions.feedback.post.dirty]: (state, action) => {
    const { isDirty } = action.payload;

    if (isDirty) {
      return {
        ...state,
        status: addingStatusAction(state, {
          id: "",
          status: "dirty",
          type: "feedback",
        }),
      };
    }

    return {
      ...state,
      status: removingStatusAction(state, {
        id: "",
        status: "dirty",
        type: "feedback",
      }),
    };
  },

  [actions.feedback.post.request]: (state) => ({
    ...state,
    status: addingStatusAction(state, {
      id: "",
      status: "patch",
      type: "feedback",
    }),
  }),

  [actions.feedback.post.error]: (state, action) => ({
    ...state,
    status: raisingErrors(state.status, {
      type: "feedback",
      code: get(action.payload, "response.status"),
    }),
  }),

  [actions.feedback.post.success]: (state, action) => ({
    ...state,
    feedback: action.payload,
    status: clearingErrors(removingStatusAction(state, {
      id: "",
      status: "patch",
      type: "feedback",
    }), "feedback"),
  }),

  [actions.get.request]: (state) => ({ ...state, status: { ...state.status, loading: true } }),
  [actions.get.success]: (state, action) => reduceGame({ state, action }),
  [actions.post.success]: (state, action) => reduceGame({ state, action }),
  [actions.post.error]: (state, action) => ({
    ...state,
    status: raisingErrors(state.status, {
      type: "game",
      status: "create",
      code: get(action.payload, "response.status"),
    }),
  }),
  [actions.perspectives.selected]: (state, action) => {
    const { id } = action.payload;
    const nextState = { ...state };

    nextState.selected.perspectives = xor(state.selected.perspectives, [id]);

    return nextState;
  },

  [actions.session.set]: (state, action) => ({
    ...state,
    selected: {
      ...state.selected,
      deck: {
        ...state.selected.deck,
        language: action.payload.languages.deck,
      },
    },
  }),

  [actions.team.change]: (state, action) => {
    const { id } = action.payload;
    const nextState = { ...state };

    nextState.selected.team = id;

    return nextState;
  },

  [actions.topaasia.pick]: (state, action) => {
    const { id } = action.payload;
    const nextState = { ...state };

    nextState.selected.topaasia = { ...nextState.selected.topaasia, round: id };

    return nextState;
  },

  [rounds.actions.current.round.success]: (state, action) => ({
    ...state,
    current: {
      ...state.current,
      round: reduceRound({ state, action }),
    },
  }),

  [actions.waitplayers.patch.success]: (state, action) => (
    reduceGame({ state, action })
  ),

  [actions.preround.nextRound.success]: (state, action) => (
    reduceGame({ state, action })
  ),

  [actions.setGameCodeClosed.request]: (state, action) => {
    const newGame = { ...state };
    newGame.gameCodeClosed = action.payload.gameCodeClosed;
    return newGame;
  },

  [actions.setGameCodeClosed.success]: (state, action) => {
    const newGame = { ...state };
    newGame.gameCodeClosed = action.payload.gameCodeClosed;
    return newGame;
  },

  [actions.enablePlayerGameNotes.request]: (state, action) => {
    const newGame = { ...state };
    newGame.enablePlayerGameNotes = action.payload.enablePlayerGameNotes;
    return newGame;
  },

  [actions.enablePlayerGameNotes.success]: (state, action) => {
    const newGame = { ...state };
    newGame.enablePlayerGameNotes = action.payload.enablePlayerGameNotes;
    return newGame;
  },
}, initialState);
