import { backendService } from "../../services";
import { utils } from "../shared";

export const get = async ({ gameId, kind = "topaasia", roundId = null }) => {
  const response = await backendService.get({
    url: `player-votes/game/${gameId}/active_poll_votes`,
    data: {
      kind,
      round_id: roundId,
    },
  });

  return { data: {
    poll: response.data.poll && {
      id: response.data.poll.id,
      kind: response.data.poll.kind,
      state: response.data.poll.state,
      roundId: response.data.poll.round_id,
    },
    votes: utils.fromSnakeToCamel(response.data.votes),
  } };
};

export const create = async ({ gameId, kind = "topaasia", roundId = null }) => {
  // /v1/player-votes/game/:game_id/create_poll(.:format)
  const response = await backendService.post({
    url: `player-votes/game/${gameId}/create_poll`,
    data: {
      kind, round_id: roundId,
    },
  });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const end = async ({ gameId, pollId }) => {
  // # PATCH /player-votes/game/{game_id}/poll/{poll_id}/end_poll
  const response = await backendService.patch({
    url: `player-votes/game/${gameId}/poll/${pollId}/end_poll`,
  });

  return { data: utils.fromSnakeToCamel(response.data) };
};
