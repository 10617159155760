import { backendService } from "../../../services";
import { utils } from "../../shared";

export const get = async (organizationId) => {
  const url = `/dashboard/organization/${organizationId}`;
  const response = await backendService.get({ url });

  return { data: utils.fromSnakeToCamel(response.data.organization) };
};

export const list = async () => {
  const url = `dashboard/organization/self-list`;
  const response = await backendService.get({ url });

  return { data: utils.fromSnakeToCamel(response.data.organizations) };
};

export const prepareDestroy = async ({ organizationId }) => {
  const url = `dashboard/organization/${organizationId}/prepare-destroy`;
  const response = await backendService.post({ url });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const getOrganizationUmbrella = async ({ organizationId }) => {
  const url = `dashboard/organization/${organizationId}/organization-umbrella`;
  const response = await backendService.get({ url });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const createChild = async ({ name, organizationId }) => {
  const url = `dashboard/organization/${organizationId}/children`;

  const response = await backendService.post({
    url,
    data: {
      name,
    },
  });

  return { data: utils.fromSnakeToCamel(response.data.organization) };
};

export const destroy = async ({ organizationId, destroyCode }) => {
  const url = `dashboard/organization/${organizationId}/destroy-organization`;
  const response = await backendService.post({
    url,
    data: {
      destroy_code: destroyCode,
    },
  });

  return { data: utils.fromSnakeToCamel(response.data) };
};
