import engine from "store/src/store-engine";

import cookieStorage from "store/storages/cookieStorage";
import localStorage from "store/storages/localStorage";
import sessionStorage from "store/storages/sessionStorage";

import defaults from "store/plugins/defaults";

const storages = [
  localStorage,
  sessionStorage,
  cookieStorage,
];

const plugins = [
  defaults,
];

export const storeService = engine.createStore(storages, plugins);
