import { createAction } from "redux-actions";

const namespaces = {
  serviceMessages: "SERVICE-MESSAGES",
};

export const serviceMessages = {
  get: {
    request: createAction(`${namespaces.serviceMessages}/GET/REQUEST`),
    success: createAction(`${namespaces.serviceMessages}/GET/SUCCESS`),
    error: createAction(`${namespaces.serviceMessages}/GET/ERROR`),
  },
};
