import { createAction } from "redux-actions";

const namespaces = {
  loginInfo: "LOGIN-INFO",
  session: "SESSION",
  organization: "ORGANIZATION",
};

export const loginInfo = {
  get: {
    request: createAction(`${namespaces.loginInfo}/GET/REQUEST`),
    success: createAction(`${namespaces.loginInfo}/GET/SUCCESS`),
    error: createAction(`${namespaces.loginInfo}/GET/ERROR`),
  },
};

export const session = {
  login: {
    request: createAction(`${namespaces.session}/LOGIN/REQUEST`),
    success: createAction(`${namespaces.session}/LOGIN/SUCCESS`),
    error: createAction(`${namespaces.session}/LOGIN/ERROR`),
  },
  link: {
    request: createAction(`${namespaces.session}/LINK/REQUEST`),
    success: createAction(`${namespaces.session}/LINK/SUCCESS`),
    error: createAction(`${namespaces.session}/LINK/ERROR`),

    reset: {
      form: createAction(`${namespaces.session}/LINK/RESET/FORM`),
    },
  },
  token: {
    exchange: {
      request: createAction(`${namespaces.session}/TOKEN/EXCHANGE/REQUEST`),
      success: createAction(`${namespaces.session}/TOKEN/EXCHANGE/SUCCESS`),
      error: createAction(`${namespaces.session}/TOKEN/EXCHANGE/ERROR`),
    },
  },
  logout: {
    request: createAction(`${namespaces.session}/LOGOUT/REQUEST`),
    success: createAction(`${namespaces.session}/LOGOUT/SUCCESS`),
  },
  organization: {
    pick: {
      request: createAction(`${namespaces.session}/${namespaces.organization}/PICK/REQUEST`),
      success: createAction(`${namespaces.session}/${namespaces.organization}/PICK/SUCCESS`),
    },
  },
  status: {
    expired: createAction(`${namespaces.session}/${namespaces.organization}/EXPIRED`),
  },
};
