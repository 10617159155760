import { call, put, cancelled, select } from "redux-saga/effects";

import * as shared from "../shared";
import * as gameModule from "../game";

import { actions, api } from ".";

const candidatesPart = {
  * get(action) {
    try {
      yield put(actions.candidates.get.request(action.payload));

      const response = yield call(api.candidates.get, action.payload);
      const payload = response ? response.data : {};

      yield put(actions.candidates.get.success(payload));
    } catch (error) {
      yield put(actions.candidates.get.error(error));
      throw error;
    }
  },
  * pick(action) {
    try {
      yield put(actions.candidates.pick.request(action.payload));
      const response = yield call(api.candidates.pick, action.payload);
      yield put(actions.candidates.pick.success(response.data));
    } catch (error) {
      yield put(actions.candidates.pick.error(error));
    }
  },
  * remove(action) {
    yield put(actions.candidates.remove.request(action.payload));
    yield call(api.candidates.remove, action.payload);
    yield put(actions.candidates.remove.success(action.payload));
  },
  * submit(action) {
    yield call(api.candidates.submit, action.payload);
  },
  * skip(action) {
    yield call(api.candidates.skip, action.payload);
  },
};

export const candidates = {
  ...candidatesPart,
  * poll(action) {
    try {
      while (true) {
        yield call(candidates.get, action);
        yield call(shared.generators.generateDelay, 3000);
      }
    } finally {
      if (yield cancelled()) {
        yield put(actions.candidates.refresh.cancelled());
      }
    }
  },
};

export const choice = {
  * patch(action) {
    try {
      yield put(actions.choice.patch.request(action.payload));

      const response = yield call(api.choice.patch, action.payload);
      const payload = response ? response.data : {};

      yield put(actions.choice.patch.success(payload));
    } catch (error) {
      yield put(actions.choice.patch.error(error));
      throw error;
    }
  },
};

export const round = {
  * get(action) {
    try {
      yield put(actions.round.get.request(action.payload));

      const { id } = action.payload;
      const response = yield call(api.round.get, id);
      const payload = response ? response.data : {};

      yield call(candidates.get, { payload: { round: { id: action.payload.id } } });

      yield put(actions.round.get.success(payload));
    } catch (error) {
      yield put(actions.round.get.error(error));
      throw error;
    }
  },
  * current(action) {
    try {
      yield put(actions.current.round.request(action.payload));

      const { game } = action;
      const response = yield call(api.round.current, game.id);
      const payload = response ? response.data : {};

      yield put(actions.current.round.success(payload));
      return payload;
    } catch (error) {
      yield put(actions.current.round.error);
      throw error;
    }
  },
  * nextRound() {
    const state = yield select();
    const game = { id: state.game.id };

    const response = yield call(gameModule.api.preround.nextRound, { game });
    const payload = response ? response.data : {};

    yield put(gameModule.actions.preround.nextRound.success(payload));
  },
};

export const pollDriver = shared.generators.generatePollDriver({
  startAction: actions.candidates.refresh.request,
  stopAction: actions.candidates.refresh.stop,
  pollSaga: candidates.poll,
});
