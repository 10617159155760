import { createAction } from "redux-actions";

const namespaces = {
  gameTimer: "GAME-TIMER",
};

export const command = {
  start: createAction(`${namespaces.gameTimer}/COMMAND/START`),
  stop: createAction(`${namespaces.gameTimer}/COMMAND/STOP`),
  pause: createAction(`${namespaces.gameTimer}/COMMAND/PAUSE`),
  resume: createAction(`${namespaces.gameTimer}/COMMAND/RESUME`),
  refresh: createAction(`${namespaces.gameTimer}/COMMAND/REFRESH`),
};
