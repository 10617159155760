import { call, put } from "redux-saga/effects";
import i18next from "i18next";

import { actions, api } from ".";

const t = i18next.getFixedT(null, "modules");

export function* post(action) {
  const { form } = action.meta;

  try {
    const response = yield call(api.create, action.payload);
    const payload = response ? response.data : {};

    if (payload.status === "success") {
      yield call(form.resetForm);
      yield call(form.setStatus, { success: true });
      yield put(actions.post.success(payload));
    } else if (payload.status === "error") {
      if (payload.reason === "invalid_email") {
        yield call(form.setErrors, { email: t("link-requests.schemas.email.format") });
      } else if (payload.reason === "trial_expired") {
        yield call(form.setErrors, { email: t("link-requests.schemas.email.trial-expired") });
      }
    }
  } catch (error) {
    yield put(actions.post.error(error));
  } finally {
    yield call(form.setSubmitting, false);
  }
}
