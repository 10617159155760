import { createAction } from "redux-actions";

const namespaces = {
  actionPlans: "ACTION-PLANS",
  actionItems: "ACTION-ITEMS",
  deck: "DECK",
  findings: "FINDINGS",
  game: "GAME",
  perspectives: "PERSPECTIVES",
  session: "SESSION",
  team: "TEAM",
  feedback: "FEEDBACK",
  topaasia: "TOPAASIA",
  preround: "PREROUND",
  waitplayers: "WAITPLAYERS",
  crystallization: "CRYSTALLIZATION",
  complete: "COMPLETE",
  tick: "TICKER",
  playerParticipations: "PLAYER-PARTICIPATIONS",
  playerIdeas: "PLAYER-IDEAS",
  gameCode: "GAME-CODE",
};

export const actionPlan = {
  dirty: createAction(`${namespaces.game}/${namespaces.actionPlans}/DIRTY`),
};

export const actionItems = {
  post: {
    request: createAction(`${namespaces.game}/${namespaces.actionItems}/POST/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.actionItems}/POST/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.actionItems}/POST/ERROR`),
    clearError: createAction(`${namespaces.game}/${namespaces.actionItems}/POST/CLEAR_ERROR`),
  },
  patch: {
    request: createAction(`${namespaces.game}/${namespaces.actionItems}/PATCH/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.actionItems}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.actionItems}/PATCH/ERROR`),
  },
  destroy: {
    request: createAction(`${namespaces.game}/${namespaces.actionItems}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.actionItems}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.actionItems}/DESTROY/ERROR`),
    clearError: createAction(`${namespaces.game}/${namespaces.actionItems}/DESTROY/CLEAR_ERROR`),
  },
};

export const deck = {
  change: createAction(`${namespaces.game}/${namespaces.deck}/CHANGE`),
};

export const findings = {
  post: {
    dirty: createAction(`${namespaces.game}/${namespaces.findings}/POST/DIRTY`),
    request: createAction(`${namespaces.game}/${namespaces.findings}/POST/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.findings}/POST/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.findings}/POST/ERROR`),
  },
};

export const playerParticipations = {
  list: {
    request: createAction(`${namespaces.game}/${namespaces.playerParticipations}/LIST/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.playerParticipations}/LIST/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.playerParticipations}/LIST/ERROR`),
  },
  get: {
    request: createAction(`${namespaces.game}/${namespaces.playerParticipations}/GET/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.playerParticipations}/GET/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.playerParticipations}/GET/ERROR`),
  },
  refresh: {
    request: createAction(`${namespaces.game}/${namespaces.playerParticipations}/REFRESH/REQUEST`),
    stop: createAction(`${namespaces.game}/${namespaces.playerParticipations}/REFRESH/STOP`),
    canceled: createAction(`${namespaces.game}/${namespaces.playerParticipations}/REFRESH/CANCELED`),
  },
  post: {
    request: createAction(`${namespaces.game}/${namespaces.playerParticipations}/POST/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.playerParticipations}/POST/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.playerParticipations}/POST/ERROR`),
  },
  patch: {
    request: createAction(`${namespaces.game}/${namespaces.playerParticipations}/PATCH/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.playerParticipations}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.playerParticipations}/PATCH/ERROR`),
  },
};

export const playerIdeas = {
  destroy: {
    request: createAction(`${namespaces.game}/${namespaces.playerIdeas}/DESTROY/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.playerIdeas}/DESTROY/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.playerIdeas}/DESTROY/ERROR`),
  },
};

export const feedback = {
  post: {
    dirty: createAction(`${namespaces.game}/${namespaces.feedback}/DIRTY/REQUEST`),
    request: createAction(`${namespaces.game}/${namespaces.feedback}/POST/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.feedback}/POST/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.feedback}/POST/ERROR`),
  },
};

export const get = {
  request: createAction(`${namespaces.game}/GET/REQUEST`),
  success: createAction(`${namespaces.game}/GET/SUCCESS`),
  error: createAction(`${namespaces.game}/GET/ERROR`),
};

export const patch = {
  request: createAction(`${namespaces.game}/PATCH/REQUEST`),
  success: createAction(`${namespaces.game}/PATCH/SUCCESS`),
  error: createAction(`${namespaces.game}/PATCH/ERROR`),
};

export const tick = {
  refresh: createAction(`${namespaces.tick}/TICK/REFRESH`),
  request: createAction(`${namespaces.tick}/TICK/REQUEST`),
  stop: createAction(`${namespaces.tick}/TICK/STOP`),
  cancelled: createAction(`${namespaces.tick}/TICK/CANCELLED`),
  success: createAction(`${namespaces.tick}/TICK/SUCCESS`),
  error: createAction(`${namespaces.tick}/TICK/ERROR`),
};

export const setGameCodeClosed = {
  request: createAction(`${namespaces.game}/${namespaces.gameCode}/CLOSED/PATCH/REQUEST`),
  success: createAction(`${namespaces.game}/${namespaces.gameCode}/CLOSED/PATCH/SUCCESS`),
  error: createAction(`${namespaces.game}/${namespaces.gameCode}/CLOSED/PATCH/ERROR`),
};

export const enablePlayerGameNotes = {
  request: createAction(`${namespaces.game}/ENABLE-PLAYER-GAME-NOTES/PATCH/REQUEST`),
  success: createAction(`${namespaces.game}/ENABLE-PLAYER-GAME-NOTES/PATCH/SUCCESS`),
  error: createAction(`${namespaces.game}/ENABLE-PLAYER-GAME-NOTES/PATCH/ERROR`),
};

export const post = {
  request: createAction(
    `${namespaces.game}/POST/REQUEST`,
    (payload) => payload.values,
    (payload) => payload.meta,
  ),
  success: createAction(`${namespaces.game}/POST/SUCCESS`),
  error: createAction(`${namespaces.game}/POST/ERROR`),
};

export const perspectives = {
  selected: createAction(`${namespaces.game}/${namespaces.perspectives}/SELECTED`),
};

export const session = {
  get: createAction(`${namespaces.game}/${namespaces.session}/GET`),
  set: createAction(`${namespaces.game}/${namespaces.session}/SET`),
};

export const team = {
  change: createAction(`${namespaces.game}/${namespaces.team}/CHANGE`),
};

export const topaasia = {
  patch: {
    request: createAction(`${namespaces.game}/${namespaces.topaasia}/PATCH/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.topaasia}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.topaasia}/PATCH/ERROR`),
  },
  pick: createAction(`${namespaces.game}/${namespaces.topaasia}/PICK`),
};

export const preround = {
  nextRound: {
    request: createAction(`${namespaces.game}/${namespaces.preround}/NEXT-ROUND/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.preround}/NEXT-ROUND/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.preround}/NEXT-ROUND/ERROR`),
  },
};

export const waitplayers = {
  patch: {
    request: createAction(`${namespaces.game}/${namespaces.waitplayers}/PATCH/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.waitplayers}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.waitplayers}/PATCH/ERROR`),
  },
};

export const crystallization = {
  patch: {
    request: createAction(`${namespaces.game}/${namespaces.crystallization}/PATCH/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.crystallization}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.crystallization}/PATCH/ERROR`),
  },
};

export const complete = {
  patch: {
    request: createAction(`${namespaces.game}/${namespaces.crystallization}/PATCH/REQUEST`),
    success: createAction(`${namespaces.game}/${namespaces.crystallization}/PATCH/SUCCESS`),
    error: createAction(`${namespaces.game}/${namespaces.crystallization}/PATCH/ERROR`),
  },
};
