import { backendService } from "../../services";
import { utils } from "../shared";

export const get = async () => {
  const url = `authentication/login-info`;
  const response = await backendService.get({ url });

  return { data: utils.fromSnakeToCamel(response.data.login_info) };
};

export const login = async ({ email, password }) => {
  const url = `authentication/login`;
  const response = await backendService.post({ url, data: { email, password } });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const linkRequest = async ({ email }) => {
  const url = `authentication/link-requests`;
  const response = await backendService.post({ url, data: { email } });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const tokenExchange = async ({ userId, token }) => {
  const url = `authentication/login/token-exchange`;
  const response = await backendService.post({
    url,
    data: {
      user_id: userId,
      token,
    },
  });

  return { data: utils.fromSnakeToCamel(response.data) };
};
