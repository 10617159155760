import { backendService } from "../../services";
import { fromSnakeToCamel } from "../shared/utils";

export const get = async (gameId) => {
  const response = await backendService.get({ url: `/games/${gameId}/relationships/players` });
  return { data: fromSnakeToCamel(response.data.players) };
};

export const remove = async ({ gameId, playerId }) => {
  const url = `/games/${gameId}/relationships/players/${playerId}`;
  await backendService.destroy({ url });
};
