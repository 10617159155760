/* eslint-disable class-methods-use-this */
import { call, put, select, retry, cancelled, delay, take } from "redux-saga/effects";

import { api } from ".";
import { generatePollDriver } from "../shared/generators";
import { gameNoteActions } from "./actions";

class GameNoteSagas {
  *get() {
    try {
      const state = yield select();

      const response = yield call(api.get, {
        gameId: state.game.id,
      });
      const payload = response || {};

      yield put(gameNoteActions.get.success(payload));
    } catch (error) {
      yield put(gameNoteActions.get.error(error));
      throw error;
    }
  }

  *patch(action) {
    try {
      const state = yield select();
      const { gameNotes } = action.payload;

      const response = yield retry(5, 5000, api.patch, {
        gameId: state.game.id,
        gameNotes,
      });
      const payload = response ? response.data : {};

      yield put(gameNoteActions.patch.success(payload));
    } catch (error) {
      yield put(gameNoteActions.patch.error(error));
    }
  }

  *refresh() {
    try {
      while (true) {
        yield put(gameNoteActions.get.request());
        yield take([gameNoteActions.get.success, gameNoteActions.get.error]);
        yield delay(5000);
      }
    } finally {
      if (yield cancelled()) {
        yield put(gameNoteActions.refresh.canceled());
      }
    }
  }

  get pollDriver() {
    return generatePollDriver({
      startAction: gameNoteActions.refresh.request,
      stopAction: gameNoteActions.refresh.stop,
      pollSaga: this.refresh,
    });
  }
}

export const gameNoteSagas = new GameNoteSagas();
