import { Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { Component } from "react";

import * as login from "../../modules/login";
import { tokenService } from "../../services";

class AuthenticatedRoute extends Component {

  static isAuthenticated() {
    const token = tokenService.get();

    if (token && tokenService.isActive(token)) {
      return true;
    }

    tokenService.remove();
    return false;
  }

  render() {
    const { component: RouteComponent } = this.props;
    const { exact, open, path, strict } = this.props;
    const isAuthorized = AuthenticatedRoute.isAuthenticated();
    const routeProps = { exact, path, strict };

    if (open || isAuthorized) {
      return <Route {...routeProps} render={(props) => <RouteComponent {...props} isAuthorized={isAuthorized} />} />;
    }

    this.props.dispatch(login.actions.session.status.expired());
    return <div></div>;
  }
}

AuthenticatedRoute.defaultProps = {
  exact: true,
  open: false,
  strict: true,
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
  exact: PropTypes.bool,
  match: PropTypes.object,
  open: PropTypes.bool,
  path: PropTypes.string.isRequired,
  strict: PropTypes.bool,
  dispatch: PropTypes.func,
};

export default connect()(AuthenticatedRoute);
