import { createAction } from "redux-actions";

const namespaces = {
  deck: "DECK",
  decks: "DECKS",
};

export const deck = {
  get: {
    request: createAction(`${namespaces.deck}/GET/REQUEST`),
    success: createAction(`${namespaces.deck}/GET/SUCCESS`),
    error: createAction(`${namespaces.deck}/GET/ERROR`),
  },
  preview: {
    request: createAction(`${namespaces.deck}/PREVIEW/REQUEST`),
    success: createAction(`${namespaces.deck}/PREVIEW/SUCCESS`),
    error: createAction(`${namespaces.deck}/PREVIEW/ERROR`),
    close: createAction(`${namespaces.deck}/PREVIEW/CLOSE`),
  },
};

export const get = {
  request: createAction(`${namespaces.decks}/GET/REQUEST`),
  success: createAction(`${namespaces.decks}/GET/SUCCESS`),
  error: createAction(`${namespaces.decks}/GET/ERROR`),
};
