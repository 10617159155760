import i18next from "i18next";
import { camelCase, cloneDeep, compact, find, findIndex, flatten, map, pick, remove, set, some, sortBy } from "lodash";
import { fromSnakeToCamel } from "../shared/utils";

const t = i18next.getFixedT(null, "shared");

export const decksToOptions = ({ decks = [], languages = [] }) =>
  languages.map((language) => ({
    label: `${t(`languages.${language}.in-language`)} (${language.toUpperCase()})`,
    options: sortBy(
      decks
        .filter((deck) => deck.title[language])
        .map((deck) => ({
          label: deck.title[language],
          value: `${language}-${deck.id}`,
        })),
      "label",
    ),
  }));

export const compressDeckChanges = (baseDeck, changes) => {
  const deck = { ...baseDeck };

  return changes.reduce(
    (memo, change) => {
      if (change.undone) {
        return { ...memo };
      }
      if (change.entity === "card") {
        return applyCardChange(
          memo,
          change.operation,
          change.identifier,
          change.newValue,
          change.createdAt,
        );
      }
      if (change.entity === "suit") {
        return applySuitChange(memo, change.identifier, change.newValue);
      }

      return applyDeckChange(memo, change.entity, change.newValue);
    },
    { ...deck },
  );
};

function applyCardChange(deck, operation, cardId, newValue, createdAt) {
  if (operation === "create") {
    return applyCardCreate(deck, cardId, newValue, createdAt);
  }
  if (operation === "edit") {
    return applyCardEdit(deck, cardId, newValue);
  }
  if (operation === "destroy") {
    return applyCardDestroy(deck, cardId);
  }

  throw new Error("Unsupported operation:", operation);
}

function applyCardCreate(deck, cardId, newValue, createdAt) {
  const cardData = fromSnakeToCamel(JSON.parse(newValue));

  const card = {
    id: cardId,
    suitId: cardData.suitId,
    title: cardData.title,
    description: cardData.description,
    createdAt,
  };

  const suit = deck.suits.find((s) => s.id === cardData.suitId);
  const index = deck.suits.indexOf(suit);

  const newSuit = { ...suit, cards: [...suit.cards, card] };

  const newSuits = Array.from(deck.suits);
  newSuits.splice(index, 1, newSuit);

  return { ...deck, suits: newSuits };
}

function applyCardEdit(deck, cardId, newValue) {
  const card = find(flatten(map(deck.suits, "cards")), { id: cardId });
  const cardData = fromSnakeToCamel(JSON.parse(newValue));

  const oldSuitId = card.suitId;
  const newSuitId = cardData.suitId;

  const newCard = {
    ...card,
    ...pick(cardData, ["title", "description", "suitId"]),
  };

  const newSuits = cloneDeep(deck.suits);

  if (!newSuitId || oldSuitId === newSuitId) {
    const oldSuit = find(newSuits, (s) => some(s.cards, { id: cardId }));
    const cardIndex = findIndex(oldSuit.cards, { id: cardId });

    // Use Lodash's set to immutably update the card in its current suit
    set(oldSuit, ["cards", cardIndex], newCard);
  } else {
    // Remove card from old suit
    const oldSuit = find(newSuits, (s) => some(s.cards, { id: cardId }));
    remove(oldSuit.cards, (c) => c.id === cardId);

    // Add card to new suit
    const newSuit = newSuits.find((s) => s.id === newSuitId);
    newSuit.cards.push(newCard);
  }

  return { ...deck, suits: newSuits };
}

function applyCardDestroy(deck, cardId) {
  const suit = deck.suits.find((s) => s.cards.find((c) => c.id === cardId));
  if (!suit) {
    return { ...deck };
  }

  const suitIndex = deck.suits.findIndex((s) => s === suit);
  const newSuit = {
    ...suit,
    cards: suit.cards.filter((c) => c.id !== cardId),
  };

  const newSuits = Array.from(deck.suits);
  newSuits.splice(suitIndex, 1, newSuit);

  return { ...deck, suits: newSuits };
}

function applySuitChange(deck, suitId, newValue) {
  const suit = deck.suits.find((s) => s.id === suitId);
  const index = deck.suits.indexOf(suit);
  const newSuit = {
    ...suit,
    title: JSON.parse(newValue).title || "",
  };
  const newSuits = Array.from(deck.suits);
  newSuits.splice(index, 1, newSuit);

  return { ...deck, suits: newSuits };
}

function applyDeckChange(deck, entityName, newValue) {
  if (entityName === "deck_subscriptions") {
    return applyDeckSubscriptionChange(deck, newValue);
  }
  if (entityName === "supported_game_modes") {
    return applySupportedGameModesChange(deck, newValue);
  }

  const fieldName = camelCase(entityName);
  return { ...deck, [fieldName]: newValue };
}

function applyDeckSubscriptionChange(deck, newValue) {
  const organizationIds = newValue.split(",");
  return { ...deck, subscriptions: organizationIds };
}

function applySupportedGameModesChange(deck, newValue) {
  const supportedGameModes = compact(newValue.split(","));
  return { ...deck, supportedGameModes };
}
