import { combineReducers } from "redux";
import { handleAction, handleActions } from "redux-actions";
import { pick, random } from "lodash";

import * as game from "../game";

const initialStates = {
  byId: {},
  status: {
    loading: false,
  },
};

const picker = (perspective) => ({
  ...pick(perspective, ["id", "description", "title", "type"]),
  rotation: random(-2, 2),
});

const byId = handleAction(game.actions.get.success, (state, action) => {
  const { perspectives } = action.payload;

  return perspectives.reduce((memo, perspective) => ({
    ...memo,
    [perspective.id]: picker(perspective),
  }), {})
}, initialStates.byId);

const status = handleActions({
  [game.actions.get.request]: () => ({ loading: true }),
  [game.actions.get.success]: () => ({ loading: false }),
  [game.actions.get.error]: () => ({ loading: false }),
}, initialStates.status);

export const reducer = combineReducers({
  byId,
  status,
});
